<template>
  <div>
    <div v-if="mode === 'modal'"></div>
    <div v-if="mode === 'inplace'" class="csv-uploader-panel">
      <div
       
        class="alignments"
        :class="{ highlight, disabled: false }"
        @drop.prevent.stop="onDrop"
        @dragenter.prevent.stop="highlight = true"
        @dragover.prevent.stop="highlight = true"
        @dragleave.prevent.stop="highlight = false"
      >
        <div class="container" 
        :style="styles"
        
        >
          <div 
          @click="clearInputFile();$refs.inputFile.click()"
          class="upload-image">
            <img src="../assets/uploadplus.svg" />
          </div>

          <nitrozen-button
            :rounded="true"
            class="upload-button"
            theme="secondary"
            @click="clearInputFile();$refs.inputFile.click()"
          >
            Select a file
          </nitrozen-button>
          <span>
            or drag and drop file here
          </span>
          <div v-if="inputFileMeta.name" class="file-name">
            <span class="bold-xxxs cl-Mako">{{ inputFileMeta.name }}</span>
          </div>

          <span class="meta-info">
            {{ acceptedFileTypesText }}
          </span>
          <span class="meta-info">
            {{ note }}
          </span>
          <span v-if="error || errorMessage" class="parent-error">{{
            error || errorMessage
          }}</span>
          
        </div>
        <slot name="bottom"></slot>
        <input
          ref="inputFile"
          :accept="acceptedMIMETypesString()"
          name="inputFile"
          type="file"
          @change="handleFile($event.target.files)"
        />
      </div>
      <ProgressBar 
      v-if="file && file.name && !isFileCleared"
      :complete-count="fileUploadPercentage" 
      @click="clearInputFile"
      :file-name="file.name"
      :total-size="totalSize"
      :uploaded="fileUploaded"
      
      />

    </div>
  </div>
</template>

<script>
import { flatBtn, NitrozenButton, strokeBtn } from "@gofynd/nitrozen-vue";
import MainService from "./../services/main-service";
import ProgressBar from "../components/ProgressBar.vue";

export default {
  name: "csv-uploader",
  SUPPORTED_INPUT_FILE_TYPES: ["csv", "xlsx", "xls"],
  components: {
    "nitrozen-button": NitrozenButton,
    ProgressBar
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  data() {
    return {
      supportedFile : true,
      fileURL: "",
      file: null,
      isUploading: false,
      errorMessage: "",
      inputFileMeta: {},
      highlight: false,
      fileJSON: {},
      storeTable: {
        meta: { fields: [] },
        data: [],
      },
    };
  },

  computed: {
    styles() {
      return {
        opacity: this.file && this.file.name && !this.isFileCleared ? 0.5: 1
      }
    }
  },
  props: {
    mode: {
      type: String,
      default: "inplace",
    },
    modalClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default:
        "Drag and drop a file here or click to upload\n" +
        "Accepted file types are *.csv",
    },
    title: {
      default: "",
      type: String,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
    isFileCleared: {
      default: false,
      type: Boolean,
    },
    maxSize: {
      type: Number, // in KB
      default: 2048,
    },
    acceptedMIMETypes: {
      type: Array,
      default: () => {
        return ["image/jpeg", "image/png"];
      },
    },
    acceptedFileTypesText: {
      type: String,
      default: () => {
        return ``;
      },
    },
    note: {
      type: String,
      default: () => {
        return ``;
      },
    },
    error: {
      type: String,
      default: () => {
        return ``;
      },
    },
    urlUpload: {
      type: Boolean,
      default: true,
    },
    totalSize: {
      type: String,
      default: ''
    },
    fileUploadPercentage:{
      type: Number,
      default: 0
    },
    fileUploaded : {
      type: Boolean,
      default: false
    }
  },
  
  methods: {
    clearInputFile() {
      this.$refs.inputFile.value = "";
      this.inputFileMeta = { name: "" };
      this.file = null;
      this.$emit('clearData');
    },
    acceptedMIMETypesString() {
      return this.acceptedMIMETypes.toString();
    },
    onDrop(e) {
      this.highlight = false;
      const files = e.dataTransfer.files;
      this.handleInputFile(files);
      this.$emit("onDrop", e);
    },
    handleInputFile(files) {
      if (!files || !files.length) {
        this.$snackbar.global.showError("No file selected");
        return;
      }
      const file = files[0];
      const fileExtension = file.name.split(".").pop();
      if(fileExtension.includes(file.type)){
        this.file = file;
      }
    },
    $closeModal() {
      this.$emit("closeModal");
    },
    getValidFile(files) {
      this.errorMessage = "";
      if (files.length > 1) {
        this.errorMessage = "You have selected more than one files";
        return null;
      }
      if (files.length === 1) {
        const file = [...files][0];
        if (!this.acceptedMIMETypes.includes(file.type)) {
          this.errorMessage = `${file.type} file type not supported`;
          return null;
        }
        return file;
      }
    },
    handleFile(files) {
      this.errorMessage = "";
      const file = this.getValidFile(files);
      this.file = file;
      this.$emit("input", { file });
    },
    $uploadFile() {
      this.errorMessage = "";
      
      if (this.file) {
        this.$emit("uploadButtonClicked", { file: this.file });
      } else if (this.fileURL) {
        this.$emit("uploadButtonClicked", { fileURL: this.fileURL });
      } else {
        this.errorMessage = "File not selected";
      }
    },
    uploadToGrindor(namespace, fileUploadProgress) {
      this.errorMessage = "";
      if (!namespace) return;
      if (this.file) {
        this.isUploading = true;
        let body = {
          file_name: this.file.name,
          content_type: this.file.type,
          size: this.file.size,
          params: {
            extension_slug: "seo",
          },
        };
        let file = this.file;
        let response = null;
        return MainService.uploadToGrindorStart(namespace, body)
          .then((res) => {
            response = res.data;
            let uploadUrl =
              response.upload && response.upload.url ? response.upload.url : "";
            if (uploadUrl) {
              return MainService.uploadToGrindorS3(uploadUrl, file, fileUploadProgress);
            } else {
              this.$snackbar.global.showError("Failed to upload file");
              throw { message: "Failed to upload file" };
            }
          })
          .then(() => {
            return MainService.uploadToGrindorComplete(namespace, response);
          })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            this.clearInputFile();
            this.$snackbar.global.showError(err.message);
            throw err;
          })
          .finally(() => {
            this.isUploading = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../less/main.less";

.grindor-modal {
  width: 100%;

  /deep/ .modal-container {
    padding-bottom: 24px;
  }
}

.csv-uploader-panel {
  .m-24 {
    margin: 24px;
  }

  input[type="file"] {
    display: none;
  }

  .alignments {
    &.highlight {
      .container {
        filter: blur(1px);
      }
    }

    .container {
      padding: 24px;
      display: flex;
      flex-direction: column;
      background-color: #F5F8FF;
      align-items: center;
      color: #61636a;
      font-size: 16px;
      text-align: center;
      border: 1px dashed #e0e0e0;
      border-radius: 4px;
      height: 13rem;
      line-height: 1.8rem;
      

      .upload-image {
        width: 100%;
        margin-top: 2rem;
        cursor: pointer;

      }

      .upload-button {
        padding: 0.5rem;
      }

      .file-name {
        color: #41434c;
        font-size: 14px;
      }

      .image-url {
        margin: 8px 0 24px;
        width: 80%;
      }

      .meta-info {
        color: @DustyGray2;
        font-size: 14px;
        line-height: 20px;
      }

      .parent-error {
        margin: 12px 0px;
        color: #fa3f4d;
        font-size: 12px;
      }
    }
  }

  .upload-btn {
    float: right;
    margin-right: 24px;
  }
}
</style>
