<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
@import './../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css';

</style>
