let company_id = null;

export const setCompany = companyId => {
  company_id = companyId;
};

export const getCompany = () => {
  return company_id;
};

export const debounce = (func, timeout) => {
  let timer;
  return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
  }
}

// SUPPORTED FILE TYPES FOR BULK UPLOAD
export const SUPPORTED_INPUT_FILE_TYPES = ["xlsx", "xls"];
export const ACCEPTED_MIME_TYPE = [
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]