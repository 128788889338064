<template>
  <div class="bulk-modal">
    <div class="bulk-modal-container">
      <div class="modal-title">
        Bulk Action For Alt-text
        <div class="close-icon" @click="onClose">
          <img src="../../assets/close-icon.png" class="close" />
        </div>
      </div>
      <div>
        <div class="file-container">
          <div class="download-template">
            <div class="select-type">
              <div class="title">1. Download</div>
              <div class="download-type" @click="getElement">
                <nitrozen-dropdown
                  class="download-box"
                  :items="file_type"
                  v-model="selected_file_type"
                  :enable_select_all="true"
                  @change="onDownloadFileTypeChange"
                />
              </div>
            </div>
            <nitrozen-dropdown
              class="dropdown"
              label="Department"
              :placeholder="placeholderDepartment"
              :items="department_list"
              v-model="selectedDepartments"
              :multiple="true"
              :enable_select_all="true"
              @change="onDepartmentChange"
            />
            <nitrozen-dropdown
              class="dropdown"
              label="Category"
              :placeholder="placeholderCategory"
              :searchable="true"
              :items="filtered_categories"
              v-model="selectedCategories"
              :multiple="true"
              :enable_select_all="false"
              @searchInputChange="debouncedCategorySearch"
              @change="onCategoryChange"
              @scroll="debouncedScroll"
            />
            <button class="download-btn" @click="() => downloadExcel()">
              <div class="wrapper" v-show="!isButtonDisable">Download Data</div>
              <img class="loader" v-show="isButtonDisable" :src="getLoader" />
            </button>
            <div class="note-container">
              <p class="download-note">
                Note: Product Alt Text data column values will be empty upon
                download.
              </p>
            </div>
          </div>
          <div class="upload-file">
            <div class="upload-title">
              <div class="title">2. Upload</div>
            </div>
            <FileUploader
              :class="{ highlight, disabled: false }"
              ref="fileuploader"
              :total-size="totalSize"
              :acceptedFileTypesText="acceptedFileTypesText"
              :isFileCleared="isFileCleared"
              :note="'Note: Upload Products (max 300 at a time)'"
              :acceptedMIMETypes="acceptedMIMETypes"
              :error="csvModalError"
              :title="'Upload the File'"
              @input="onFileSelect"
              @onDrop="onDropFile"
              @clearInputFile="clearInputFile"
              @clearData="clearData"
              :fileUploadPercentage="fileUploadPercentage"
              :file-uploaded="fileUploadPercentage === 100"
            ></FileUploader>
          </div>
        </div>
        <div class="upload-status">
          <ValidationDetails
            @onClose="onClose"
            :details="verified_data"
            :validated_id="validated_id"
            :selectedChannel="selectedChannel"
          ></ValidationDetails>
        </div>
        <div
          v-if="verified_data.length <= 0 && !isPageLoading"
          class="empty-state"
        >
          <img src="../../assets/empty.png" width="100%" />
        </div>
        <div>
          <loader v-if="isPageLoading"></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NitrozenDropdown, strokeBtn, flatBtn } from "@gofynd/nitrozen-vue";
import ValidationDetails from "./alt-text-validation-details.vue";
import FileUploader from "./../file-uploader.vue";
import MainService from "../../services/main-service";
import { bytesToSize } from "../../helper/sizeConverter";
import get from "lodash/get";
import { parse } from "json2csv";
import papa from "papaparse";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Loader from "../../components/loader.vue";
import {
  ACCEPTED_MIME_TYPE,
  debounce,
  SUPPORTED_INPUT_FILE_TYPES,
} from "../../helper/utils";

const PUBLIC_SIGNED_URL_EXPIRY = 60 * 60; // in minutes
const PAGINATION = {
  current: 1,
  limit: 50,
  has_next: true,
};
const WORKSHEET_COLUMNS = [
  {
    header: "Name",
    key: "name",
    width: 15,
  },
  {
    header: "Item-Code",
    key: "item_code",
    width: 15,
  },
  {
    header: "Brand",
    key: "brand",
    width: 15,
  },
  {
    header: "image_url",
    key: "image_url",
    width: 20,
  },
  {
    header: "alt_text",
    key: "alt_text",
    width: 30,
  },
  {
    header: "uid",
    key: "uid",
    width: 30,
  },
];

const MANDATORY_COLUMNS = [
  {
    column: "A",
    header: "Name",
    col_index: 0,
  },
  {
    column: "B",
    header: "Item-Code",
    col_index: 1,
  },
  {
    column: "C",
    header: "Brand",
    col_index: 2,
  },
  {
    column: "D",
    header: "image_url",
    col_index: 3,
  },
  // {
  //   column: "E",
  //   header: "alt_text",
  //   col_index: 4,
  // },
  {
    column: "F",
    header: "uid",
    col_index: 5,
  },
];

export default {
  components: {
    "nitrozen-dropdown": NitrozenDropdown,
    FileUploader,
    ValidationDetails,
    Loader,
  },
  directives: {
    strokeBtn,
    flatBtn,
  },
  props: {
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      acceptedFileTypesText: "Accepted file types: .xlsx and .xls",
      isFileCleared: false,
      enableBtn: false,
      totalSize: "",
      csvModalError: "",
      highlight: true,
      isPageLoading: false,
      selectedDepartments: null,
      selectedCategories: null,
      categories_data: [],
      brands_list: [],
      filtered_brands: [],
      categories_list: [],
      filtered_categories: [],
      department_list: [],
      selectedBrands: null,
      selectedFilters: [],
      sample_product_data: [],
      verified_data: [],
      validated_id: null,
      fileUploadStatus: {
        error: false,
        message: "",
      },
      file_type: [
        { text: "Excel (.xlsx)", value: "excel" },
        // { text: "Csv (.csv)", value: "csv" },
      ],
      selected_file_type: "excel",
      filtered_department_names: [],
      filtered_category_names: [],
      filtered_brand_names: [],
      fileUploadPercentage: 0,
      isValidFile: true,
      isButtonDisable: false,
      categoryPagination: { ...PAGINATION },
      selectedDepartmentSlug: [],
      selectedCategorySlug: [],
      selectedBrandSlug: [],
      searchQuery: "",
    };
  },

  computed: {
    getLoader() {
      return require("../../assets/loader.gif");
    },
    acceptedMIMETypes() {
      return ACCEPTED_MIME_TYPE;
    },
    placeholderDepartment() {
      if (this.filtered_department_names.length > 0) {
        let placeholderString = "";
        this.filtered_department_names.map((item) => {
          placeholderString += item.text + ",";
        });
        return placeholderString;
      }
      return "Choose Department(s)";
    },
    placeholderCategory() {
      if (this.filtered_category_names.length > 0) {
        let placeholderString = "";
        this.filtered_category_names.map((item) => {
          placeholderString += item.text + ",";
        });
        return placeholderString;
      }
      return "Choose Categorie(s)";
    },
  },
  methods: {
    async onFileSelect(e) {
      this.verified_data = [];
      this.isFileCleared = false;
      Promise.resolve()
        .then(() => {
          this.fileUploadPercentage = 0;
          if (e.file) {
            this.file = e.file;
            this.totalSize = bytesToSize(e.file.size);
            const fileExtension = e.file.name.split(".").pop();
            if (!SUPPORTED_INPUT_FILE_TYPES.includes(fileExtension)) {
              this.isFileCleared = false;
              this.$snackbar.global.showError("Unsupported file format");
              this.clearInputFile();
              return;
            }
            this.isValidFile = true;
            this.validateExcel(e);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    onDropFile(e) {
      this.highlight = false;
      const files = e.dataTransfer.files;
      if (!files || !files.length) {
        this.$snackbar.global.showError("No file selected");
        return;
      }
      this.file = files[0];
      e.file = files[0];
      FileUploader.data().file = e.file;
      this.onFileSelect(e);
    },
    uploadFile(e) {
      this.isPageLoading = true;
      this.fileUploadedSuccessfully = false;
      this.enableBtn = false;
      if (e && e.file) {
        return this.$refs.fileuploader
          .uploadToGrindor(
            "platform-extensions",
            this.$route.params.company_id,
            this.fileUploadProgress
          )
          .then(({ data }) => {
            if (data.cdn && data.cdn.url) {
              return this.getPublicUrl([data.cdn.url], PUBLIC_SIGNED_URL_EXPIRY)
                .then((publicUrlResponse) => {
                  let public_signed_url = get(publicUrlResponse, "urls.0.url");
                  return this.uploadBulkAction({
                    file_url: public_signed_url,
                    original_file_url: data.cdn.url,
                  });
                })
                .then((res) => {
                  if (res) {
                    this.fileUploadPercentage = 100;
                    this.$snackbar.global.showSuccess(
                      "File Uploaded successfully"
                    );
                  }
                })
                .catch((err) => {
                  this.fileUploadPercentage = 0;
                  this.clearInputFile();
                  return err;
                });
            }
          })
          .catch((err) => {
            this.fileUploadPercentage = 0;
            this.$snackbar.global.showError(err.message);
            this.clearInputFile();
            return err;
          });
      }
    },
    // async validateCSV(e) {
    //   this.isValidFile = true;
    //   papa.parse(e.file, {
    //     complete: (result) => {
    //       WORKSHEET_COLUMNS.every((col, index) => {
    //         if (col.key != result.data[0][index]) {
    //           this.isValidFile = false;
    //           this.$snackbar.global.showError(
    //             `${col.key} header not included!`
    //           );
    //           return false;
    //         }
    //         return true;
    //       });
    //       for (let i = 1; i < result.data.length - 1; i++) {
    //         MANDATORY_COLUMNS.every((ele) => {
    //           if (result.data[i][ele.col_index] == "") {
    //             this.isValidFile = false;
    //             this.$snackbar.global.showError(
    //               `${ele.header} field shoundnt be empty !!`
    //             );
    //             return false;
    //           }
    //           return true;
    //         });
    //       }
    //       this.onValidatoinComplete(e);
    //     },
    //   });
    // },
    async validateExcel(e) {
      this.isValidFile = true;
      const wb = new ExcelJS.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          let worksheet = workbook.worksheets[0];
          let headerRow = worksheet.getRow(1).values;

          WORKSHEET_COLUMNS.every((col, index) => {
            if (col.header != headerRow[index + 1]) {
              this.isValidFile = false;
              this.$snackbar.global.showError(
                `Header not included ${col.header}`
              );
              return false;
            }
            return true;
          });

          MANDATORY_COLUMNS.every((col) => {
            worksheet.getColumn(col.column).eachCell((cell, rowNumber) => {
              if (rowNumber != 0 && (cell.value == null || cell.value == "")) {
                this.isValidFile = false;
                this.$snackbar.global.showError(
                  `${col.header} field shoudnt be empty`
                );
                return false;
              }
            });
            return true;
          });
          this.onValidatoinComplete(e);
        });
      };
    },
    onValidatoinComplete(e) {
      if (this.isValidFile) {
        this.uploadFile(e);
      } else {
        this.$snackbar.global.showError("Invalid Columns In File");
        this.clearInputFile();
      }
    },
    fileUploadProgress(progressEvent) {
      this.fileUploadPercentage =
        (100 * progressEvent.loaded) / progressEvent.total;
    },
    async uploadBulkAction(data) {
      await this.getAllBrands();
      let brands = this.brands_list.map((b) => b.text);
      return MainService.uploadBulkActionTemplate({
        ...data,
        brands,
        app: this.selectedChannel,
      })
        .then(async (res) => {
          if (res.data.success) {
            this.validated_id = res.data.validated_id;
            this.verified_data = [...res.data.data];
            return res;
          } else {
            this.$snackbar.global.showError(res.data.message); // error logs
            this.clearInputFile();
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching logs`);
        })
        .finally(() => {
          this.isPageLoading = false;
        });
    },
    async getPublicUrl(urls, expiry) {
      return await MainService.getPublicUrl({
        expiry,
        urls,
      }).then((res) => {
        return res.data;
      });
    },
    clearInputFile() {
      this.verified_data = [];
      this.isFileCleared = true;
      this.enableBtn = false;
      this.$refs.fileuploader.clearInputFile();
      this.file = null;
    },
    clearData() {
      this.verified_data = [];
    },
    async getDepartments() {
      return await MainService.getAllDepartments({
        app: this.selectedChannel,
      })
        .then(({ data }) => {
          if (data.success) {
            if (data.data.items) {
              data.data.items.map((item) => {
                this.department_list.push({
                  text: item.name,
                  value: item.uid,
                  slug: item.slug,
                });
              });
              this.department_list.sort((a, b) =>
                a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1
              );
            }
          } else {
            this.$snackbar.global.showError(data.message);
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching departments`);
        });
    },
    async getAllCategories(searchQuery) {
      let pageNo = this.categoryPagination.current;
      let pageSize = this.categoryPagination.limit;
      return await MainService.getAllCategories({
        app: this.selectedChannel,
        pageNo,
        pageSize,
        q: searchQuery,
      })
        .then(({ data }) => {
          this.categoryPagination.has_next = data?.data?.page?.has_next;
          this.categoryPagination.current = data?.data?.page?.current + 1;
          this.categories_data = data?.data?.items;
          let currentFilterCat = this.categories_data.map((cat) => {
            return {
              text: cat.name,
              value: cat.uid,
              slug: cat.slug,
            };
          });
          this.categories_list = [...this.categories_list, ...currentFilterCat];
          this.categories_list.sort((a, b) =>
            a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1
          );
          const filtered_category = this.categories_list;
          const jsonObject = filtered_category.map(JSON.stringify);
          const uniquSet = new Set(jsonObject);
          this.filtered_categories = Array.from(uniquSet).map(JSON.parse);
          this.filtered_categories.sort((a, b) =>
            a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1
          );
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching categories`);
        });
    },
    async searchCategories(query) {
      return await MainService.getAllCategories({
        app: this.selectedChannel,
        q: query,
      })
        .then(({ data }) => {
          let currentFilterCat = data?.data?.items?.map((cat) => {
            return {
              text: cat.name,
              value: cat.uid,
              slug: cat.slug,
            };
          });
          this.categories_list = [...this.categories_list, ...currentFilterCat];
          this.categories_list.sort((a, b) =>
            a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1
          );
          const filtered_category = currentFilterCat;
          const jsonObject = filtered_category.map(JSON.stringify);
          const uniquSet = new Set(jsonObject);
          this.filtered_categories = Array.from(uniquSet).map(JSON.parse);
          this.filtered_categories.sort((a, b) =>
            a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1
          );
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching categories`);
        });
    },
    async getAllBrands() {
      return MainService.getAllBrands({ app: this.selectedChannel })
        .then(({ data }) => {
          if (data?.success && data?.items)
            data?.items.map((item) => {
              this.brands_list.push({
                text: item.name,
                value: item.brand_id,
                logo: item.logo.url,
                slug: item.slug,
              });
            });
          this.filtered_brands = this.brands_list;
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching brands`);
        });
    },
    async getFilteredProductsData(query) {
      this.isButtonDisable = true;
      return await MainService.getFilteredAltProducts({
        f: query,
        app: this.selectedChannel,
      })
        .then(({ data }) => {
          this.sample_product_data = [];
          if (data.data) {
            data.data.map((product) => {
              product.medias.map((media) => {
                this.sample_product_data.push({
                  uid: product.uid,
                  name: product.name,
                  item_code: product.item_code,
                  brand: product.brand.name,
                  image_url: media.url,
                  alt_text: "",
                });
              });
            });
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching product`);
        })
        .finally(() => {
          this.isButtonDisable = false;
        });
    },
    async buildFilterProductQuery() {
      let filterQuery = "";
      this.selectedFilters = [];
      this.selectedDepartmentSlug = [];
      this.selectedCategorySlug = [];
      this.selectedBrandSlug = [];

      this.filtered_department_names.map((e) =>
        this.selectedDepartmentSlug.push(e.slug)
      );
      if (
        this.selectedDepartmentSlug &&
        this.selectedDepartmentSlug.length > 0
      ) {
        this.selectedFilters.push({
          key: "department",
          items: this.selectedDepartmentSlug,
        });
      }
      this.filtered_category_names.map((e) =>
        this.selectedCategorySlug.push(e.slug)
      );
      if (this.selectedCategorySlug && this.selectedCategorySlug.length > 0) {
        this.selectedFilters.push({
          key: "category",
          items: this.selectedCategorySlug,
        });
      }
      this.filtered_brand_names.map((e) => this.selectedBrandSlug.push(e.slug));
      if (this.selectedBrandSlug && this.selectedBrandSlug.length > 0) {
        this.selectedFilters.push({
          key: "brand",
          items: this.selectedBrandSlug,
        });
      }

      if (this.selectedFilters && this.selectedFilters.length > 0) {
        this.selectedFilters.map((filter, filterIndex) => {
          filterQuery += filter.key + ":";
          for (let index in filter.items) {
            if (index == filter.items.length - 1) {
              filterQuery += filter.items[index];
            } else {
              filterQuery += filter.items[index] + "||";
            }
          }
          if (this.selectedFilters.length - 1 != filterIndex) {
            filterQuery += ":::";
          }
        });
      }
      if (filterQuery) {
        await this.getFilteredProductsData(filterQuery);
      }
    },
    onDepartmentChange(value) {
      this.selectedDepartments = value;
      this.filtered_department_names = this.department_list.filter((dep) =>
        this.selectedDepartments.includes(dep.value)
      );
    },
    onCategoryChange(value) {
      this.selectedCategories = value;
      this.filtered_category_names = this.categories_list.filter((cat) =>
        this.selectedCategories.includes(cat.value)
      );
      const catJsonObject = this.filtered_category_names.map(JSON.stringify);
      const uniquCatSet = new Set(catJsonObject);
      this.filtered_category_names = Array.from(uniquCatSet).map(JSON.parse);
    },
    onBrandsChange(value) {
      this.selectedBrands = value;
      this.filtered_brand_names = this.brands_list.filter((b) =>
        this.selectedBrands.includes(b.value)
      );
    },
    // async downloadCSV() {
    //   await this.buildFilterProductQuery();
    //   this.filtered_category_names = [];
    //   this.categories_list = [];
    //   this.selectedCategories = [];
    //   this.getAllCategories();
    //   const fields = [
    //     "name",
    //     "item_code",
    //     "brand",
    //     "image_url",
    //     "alt_text"
    //   ];
    //   const opts = { fields, includeEmptyRows: true };
    //   const csvLength = this.sample_product_data.length;
    //   const csv = parse(JSON.parse(this.sample_product_data), opts);
    //   if(csv && csvLength > 0){
    //     this.sample_product_data = [];
    //     this.$snackbar.global.showSuccess(
    //                 `${csvLength} Images Downloaded successfully`
    //               );
    //   }else{
    //   this.$snackbar.global.showError(`Products not available!`);
    //   }
    //   var link = document.createElement("a");
    //   let public_signed_url =
    //     "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    //   link.setAttribute("href", public_signed_url);
    //   link.setAttribute("target", "_blank");
    //   link.setAttribute("download", "sample.csv");
    //   document.body.appendChild(link);
    //   link.click();
    // },
    getElement() {
      const ele = document.getElementsByClassName("nitrozen-select");
      ele[0].style.border = "none";
      ele[0].style.width = "7.rem";
      const inp = document.getElementsByClassName("nitrozen-select__trigger");
      inp[0].style.color = "#2E31BE";
      inp[0].style.fontWeight = 700;
    },
    async downloadExcel() {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();
      let brandsWorksheet = wb.addWorksheet("Brands");
      brandsWorksheet.state = "hidden";
      let brandsName = this.brands_list.map((b) => b.text);
      brandsWorksheet.getColumn(1).values = brandsName;

      ws.columns = WORKSHEET_COLUMNS;

      MANDATORY_COLUMNS.forEach((col) => {
        ws.getCell(`${col.column}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "90EE90" },
        };
        ws.getCell(`${col.column}1`).font = {
          bold: true,
          size: 14,
        };
        ws.getCell(`${col.column}1`).border = {
          top: { style: "hair" },
          left: { style: "hair" },
          bottom: { style: "hair" },
          right: { style: "hair" },
        };
      });
      await this.buildFilterProductQuery();
      this.filtered_category_names = [];
      this.categories_list = [];
      this.selectedCategories = [];
      this.getAllCategories();
      const dataLength = this.sample_product_data.length;
      this.sample_product_data.forEach((item, index) => {
        ws.getRow(index + 2).values = {
          uid: item.uid,
          name: item.name,
          item_code: item.item_code,
          brand: item.brand,
          image_url: item.image_url,
          alt_text: item.alt_text,
        };
      });

      for (let i = 1; i <= 100; i++) {
        ws.getCell(`B${i + 1}`).dataValidation = {
          type: "list",
          allowBlank: true,
          showErrorMessage: true,
          errorStyle: "error",
          errorTitle: "Error Input",
          error: "Input is not valid",
          formulae: [`Brands!$A$1:$A$${brandsName.length + 1}`],
        };
        ws.getCell(`C${i + 1}`).dataValidation = {
          allowBlank: true,
          showErrorMessage: true,
          errorStyle: "error",
          errorTitle: "Error Input",
          error: "Only Integer Value allowed",
          formulae: [0],
        };
        ws.getCell(`D${i + 1}`).dataValidation = {
          allowBlank: true,
          showErrorMessage: true,
          errorStyle: "error",
          errorTitle: "Error Input",
          error: "Only Integer Value allowed",
          formulae: [0],
        };
        ws.getCell(`E${i + 1}`).dataValidation = {
          allowBlank: true,
          showErrorMessage: true,
          errorStyle: "error",
          errorTitle: "Error Input",
          error: "Only Integer Value allowed",
          formulae: [0],
        };
      }

      const buf = await wb.xlsx.writeBuffer();

      saveAs(new Blob([buf]), "Alt_Text.xlsx");
      if (buf && dataLength > 0) {
        this.sample_product_data = [];
        this.$snackbar.global.showSuccess(
          `${dataLength} Images Downloaded successfully`
        );
      } else {
        this.$snackbar.global.showError(`Products not available!`);
      }
    },
    onBrandSearch(search_query) {
      if (search_query && search_query.text) {
        let filteredBrands = this.brands_list.filter(
          (item) =>
            item?.text
              ?.toLowerCase()
              .indexOf(search_query?.text?.toLowerCase()) > -1
        );
        this.filtered_brands = filteredBrands;
      } else {
        this.filtered_brands = this.brands_list;
      }
    },
    onCategorySearch(search_query) {
      this.categoryPagination = { ...PAGINATION };
      this.searchCategories(search_query?.text?.toLowerCase());
    },
    // departmentCanceled(item) {
    //   this.selectedDepartments = this.selectedDepartments.filter(
    //     (dep) => dep != item.value
    //   );
    //   this.filtered_department_names = this.filtered_department_names.filter(
    //     (dep) => dep.value != item.value
    //   );
    //   this.buildFilterProductQuery();
    // },
    categoryCanceled(item) {
      this.selectedCategories = this.selectedCategories.filter(
        (cat) => cat != item.value
      );
      this.filtered_category_names = this.filtered_category_names.filter(
        (cat) => cat.value != item.value
      );
      this.buildFilterProductQuery();
    },
    brandCanceled(item) {
      this.selectedBrands = this.selectedBrands.filter((b) => b != item.value);
      this.filtered_brand_names = this.filtered_brand_names.filter(
        (b) => b.value != item.value
      );
      this.buildFilterProductQuery();
    },
    onClose() {
      this.$emit("onClose");
    },
    debouncedScroll: debounce(function (e) {
      this.onCategoryScroll(e);
    }, 100),
    onCategoryScroll(e) {
      setTimeout(() => {
        if (
          e.scrollTop + e.clientHeight >= e.scrollHeight &&
          this.categoryPagination.has_next
        ) {
          this.getAllCategories();
        }
      }, 10);
    },
    debouncedCategorySearch: debounce(function (search) {
      this.onCategorySearch(search);
    }, 300),
  },
  mounted() {
    this.getDepartments();
    this.getAllCategories();
    this.getAllBrands();
    this.getElement();
  },
  destroyed() {
    this.getElement();
  },
};
</script>

<style lang="less" scoped>
.bulk-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%; /* Full width *//* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  margin-top: -1rem;

  .bulk-modal-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fefefe;
    width: 70%;
    min-height: 100vh;
    overflow: auto;
    bottom: 0;
    padding: 24px;
    z-index: 20;
  }

  .modal-title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
  }

  .upload-title {
    display: flex;
    justify-content: space-between;
  }


  .close {
    width: 15px;
    height: 15px;
  }

  .filter-text {
    font-weight: 700;
    font-size: 12px;
    color: #2e31be;
    margin-top: 16px;
    cursor: pointer;

    img {
      padding-right: 0.1rem;
    }
  }

  .download-btn {
    margin-top: 25px;
    width: 100%;
    border: 1px solid #2e31be;
    border-radius: 4px;
    background: white;
    height: 40px;
    padding: 9.5px 16px;
    color: #2e31be;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    .wrapper {
    width: 100%;
    > * {
      display: block;
    }
  }
  .loader {
    height: inherit;
    margin: 0 auto;
    padding: 0;
  }
  }
  .download-btn:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }

  .ntz-down-btn{
    margin-top: 10px;
    width: 100%;
  }
  .action-btn > * {
    margin: 0.8rem;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
  }

  .dropdown {
    margin-top: 16px;
  }


  .file-container {
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    .download-template {
      border: 1px solid #e0e0e0;
      width: 50%;
      padding: 24px;
      margin-right: 12px;
      border-radius: 4px;

      .note-container {
        .download-note{
          font-family: "Inter";
          color: #9b9b9b;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .select-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .upload-file {
      border: 1px solid #e0e0e0;
      width: 50%;
      padding: 24px;
      margin-left: 12px;
      border-radius: 4px;
    }
  }

  .close-icon {
    cursor: pointer;
  }
  .empty-state {
    text-align: center;
  }
}

.box{
  position: relative;
  &::after{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    z-index: 7;
  }
}
</style>
