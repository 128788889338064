/* eslint-disable */
"use strict";

import URLS from "./endpoint.service";
import axios from "axios";
import { getCompany } from "../helper/utils";

axios.interceptors.request.use((config) => {
  config.headers["x-company-id"] = getCompany();
  return config;
});

const MainService = {
  getTagsStatus() {
    return axios.get(URLS.GET_TAGS_STATUS());
  },
  //to activate toggle button
  addInjectableTag(application_id) {
    return axios.post(URLS.INJECTABLE_TAG(application_id));
  },
  removeInjectableTag(application_id) {
    return axios.delete(URLS.INJECTABLE_TAG(application_id));
  },
  getInjectableTag(application_id) {
    return axios.get(URLS.INJECTABLE_TAG(application_id));
  },
  getHomePageConfig(application_id) {
    return axios.get(URLS.GET_HOMEPAGE_CONFIG(application_id));
  },
  getCollectionConfig(application, query) {
    return axios.get(URLS.GET_COLLECTION_CONFIG(application, query));
  },
  getProductConfig(application, query) {
    return axios.get(URLS.GET_PRODUCT_CONFIG(application, query));
  },
  async saveHomePageConfig(data) {
    const saveStatus = await axios.post(URLS.SAVE_HOME_PAGE_CONFIG(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return saveStatus;
  },
  async saveCollectionPageConfig(data) {
    const saveStatus = await axios.post(
      URLS.SAVE_COLLECTION_PAGE_CONFIG(),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return saveStatus;
  },
  async saveProductPageConfig(data) {
    const saveStatus = await axios.post(URLS.SAVE_PRODUCT_PAGE_CONFIG(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return saveStatus;
  },
  getTestApi(params = {}) {
    return axios.get(URLS.GET_TEST_API());
  },
  getAllApplications(params = {}) {
    return axios.get(URLS.GET_ALL_APPLICATIONS());
  },
  uploadToGrindorStart(namespace, data) {
    return axios.post(URLS.GRINDOR_UPLOAD_START(namespace), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  uploadToGrindorS3(url, file, fileUploadProgress) {
    let contentType = file.type;
    if (!contentType) {
      contentType = mime.contentType(file.name);
    }
    return axios.put(url, file, {
      withCredentials: false,
      headers: {
        "Content-Type": contentType,
      },
      onUploadProgress: fileUploadProgress,
    });
  },
  async uploadToGrindorComplete(namespace, data) {
    const uploadStatus = await axios.post(
      URLS.GRINDOR_UPLOAD_COMPLETE(namespace),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return uploadStatus;
  },
  async uploadBulkActionSeo(data) {
    const uploadStatus = await axios.post(URLS.UPLOAD_BULK_ACTION(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return uploadStatus;
  },
  async uploadBulkActionTemplate(data) {
    const uploadStatus = await axios.post(
      URLS.UPLOAD_TEMPLATE_BULK_ACTION(),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return uploadStatus;
  },

  async uploadBulkCollection(data) {
    const uploadStatus = await axios.post(
      URLS.UPLOAD_COLLECTION_BULK_ACTION(),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return uploadStatus;
  },
  async uploadBulkSection(data) {
    const uploadStatus = await axios.post(
      URLS.UPLOAD_SECTION_BULK_ACTION(),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return uploadStatus;
  },
  async uploadBlogs(data) {
    const uploadStatus = await axios.post(
      URLS.UPLOAD_BLOGS_BULK_ACTION(),
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return uploadStatus;
  },
  getPublicUrl(data) {
    return axios.post(URLS.PUBLIC_URL(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAllDepartments(data) {
    return axios.get(
      URLS.GET_ALL_DEPARTMENTS(),
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAllCategories(data) {
    return axios.post(
      URLS.GET_ALL_CATEGORIES(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAllBrands(data) {
    return axios.post(
      URLS.GET_ALL_BRANDS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getFilteredProducts(data) {
    return axios.post(
      URLS.GET_FILTERED_PRODUCTS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getFilteredAltProducts(data) {
    return axios.post(
      URLS.GET_FILTERED_ALT_PRODUCTS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAllProducts(data) {
    return axios.post(
      URLS.GET_ALL_PRODUCTS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  // getAllProducts(application_id) {
  //   return axios.get(URLS.GET_ALL_PRODUCTS(application_id));
  // },
  getAllCollections(data) {
    return axios.post(
      URLS.GET_ALL_COLLECTIONS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAllSectionPagesData(data) {
    return axios.post(
      URLS.GET_ALL_SECTION_PAGES(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCollectionById(uid, data) {
    return axios.post(
      URLS.GET_COLLECTION_BY_ID(uid),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAllBlogs(data) {
    return axios.post(
      URLS.GET_ALL_BLOGS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getFilteredCollections(data) {
    return axios.post(
      URLS.GET_FILTERED_COLLECTIONS(),
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },

  getJobList(params) {
    return axios.post(
      URLS.GET_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getAltJobList(params) {
    return axios.post(
      URLS.GET_ALT_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCollectionJobList(params) {
    return axios.post(
      URLS.GET_COLLECTION_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSectionJobList(params) {
    return axios.post(
      URLS.GET_SECTION_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getBlogJobList(params) {
    return axios.post(
      URLS.GET_BLOG_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSearchedJob(params) {
    return axios.post(
      URLS.GET_SEARCHED_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSearchedAltJob(params) {
    return axios.post(
      URLS.GET_SEARCHED_ALT_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSearchedCollectionJob(params) {
    return axios.post(
      URLS.GET_SEARCHED_COLLECTION_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSearchedSectionJob(params) {
    return axios.post(
      URLS.GET_SEARCHED_SECTION_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getSearchedBlogJob(params) {
    return axios.post(
      URLS.GET_SEARCHED_BLOG_JOB_LIST(),
      { params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  postTestApi() {
    return axios.post(
      URLS.POST_TEST_API(),
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  updateBulkSEO(data) {
    return axios.post(URLS.UPDATE_BULK_SEO(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateBulkTemplate(data) {
    return axios.post(URLS.UPDATE_BULK_TEMPLATE(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateCollection(data) {
    return axios.post(URLS.UPDATE_A_COLLECTION(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateSection(data) {
    return axios.post(URLS.UPDATE_A_SECTION(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateBlog(data) {
    return axios.post(URLS.UPDATE_A_BLOG(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default MainService;
