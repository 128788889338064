<template>
  <div style="display: flex; gap: 24px">
    <div class="application white-box">
      <div class="section-heading">
        <h3>SEO Tiles</h3>
        <p>
          Enhance your online presence by updating SEO titles, descriptions, and
          alt text for your products, collections, and blogs
        </p>
      </div>
      <div class="card-main columns-4">
        <div class="card-innerbox">
          <div @click="clicked(3)" class="card-box">
            <div>
              <img src="./../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Products Meta</h4>
              <p>Update SEO title and description of products in bulk</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(4)" class="card-box">
            <div>
              <img src="./../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Products Alt Text</h4>
              <p>Update Alt-text images of the products in bulk</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(5)" class="card-box">
            <div>
              <img src="./../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Collection Meta</h4>
              <p>Update SEO title and description of collections in bulk</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(6)" class="card-box">
            <div>
              <img src="./../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Blogs Meta</h4>
              <p>Update SEO title and description of blogs in bulk</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(100)" class="card-box">
            <div>
              <img src="./../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Sections Meta</h4>
              <p>Update SEO title and description of section pages in bulk</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <loader></loader>
      </div>
    </div>
    <div class="learning-box">
      <div style="font-size: 14px; font-weight: 600">Learning Links</div>
      <div class="video-content">
        <div>
          <a
            href="https://platform.fynd.com/help/docs/extensions/available-extensions/seo-plus"
            target="_blank"
            ><img
              @click="playVideo"
              src="./../assets/learning.png"
              alt="setup-video"
              style="width: 58px"
          /></a>
        </div>
        <div class="learning-details">
          <p class="heading">How to setup and use this extention ?</p>
          <p class="time">WATCH: 2min 30sec</p>
        </div>
      </div>
      <div class="hr-line">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import inlineSvg from "../components/inline-svg.vue";

export default {
  components: {
    "inline-svg": inlineSvg,
  },
  data() {
    return {
      videoPath: require("./../assets/demo_sr.mp4"),
      showVideoPlayer: false,
    };
  },
  methods: {
    clicked(item) {
      this.$emit("pageChanged", { currentPageIndex: item });
    },
    playVideo() {
      this.showVideoPlayer = !this.showVideoPlayer;
    },
  },
};
</script>

<style lang="less" scoped>
.white-box {
  // height: 480px;
  border-radius: 10px;
  padding: 24px;
  background-color: #fff;
}
.section-heading h3 {
  margin-top: 0;
  font-style: normal;
  line-height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: #41434c;
  margin-bottom: 15px;
}
.section-heading p {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: Inter, sans-serif;
  color: #666666;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.columns-4 {
  grid-template-columns: 25% 25% 25% 25%;
  width: calc(100% - 54px);
}
.card-main {
  display: grid;
  grid-column-gap: 18px;
}
.card-innerbox {
  margin-bottom: 24px;
}
.card-box {
  border: 1px solid #e4e5e6;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.card-box:hover {
  box-shadow: 5px 5px 5px 0 lightgrey;
}

.select-widget {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.select-widget .inline-svg {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  transform: rotate(180deg);
  padding: 6px 6px;
  display: block;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.card-content {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}
.card-content h4 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #41434c;
}
.card-content p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #828282;
  font-family: Inter, sans-serif;
}

.card-innerbox a {
  display: block;
  width: 100%;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}
.button-container {
  width: 100%;
  float: left;
  margin-top: 25px;
  padding: 14px 0;
}
.learning-box {
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  padding: 16px 24px 0 24px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
.video-content {
  display: flex;
  gap: 4px;
}
.learning-details {
  width: auto;
}
.heading {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.time {
  color: #828282;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: 0em;
  text-align: left;
}
.hr-line > hr {
  border-color: #f2f2f2;
}
</style>
