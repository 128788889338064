<template>
  <div>
    <div class="breadcrums">
      <span
        :class="this.breadcrumbsList[0].className"
        style="cursor: pointer"
        @click="currentPageIndex = 0"
      >
        Home
      </span>
      <span
        class="normal-heading"
        @click="currentPageIndex = 1"
        v-if="currentPageIndex > 0"
      >
        <span class="arrow">></span> {{ `${selectedChannel.name}` }}
      </span>
      <span
        :class="this.breadcrumbsList[1].className"
        @click="currentPageIndex = 1"
        v-if="currentPageIndex > 0"
      >
        <span class="arrow">></span> Features
      </span>
      <span
        :class="this.breadcrumbsList[2].className"
        @click="currentPageIndex = 2"
        v-if="
          currentPageIndex == 2 ||
          currentPageIndex == 3 ||
          currentPageIndex == 4 ||
          currentPageIndex == 5 ||
          currentPageIndex == 6
        "
      >
        <span class="arrow">></span> SEO Meta
      </span>
      <span v-if="currentPageIndex > 2">
        <span
          :class="this.breadcrumbsList[3].className"
          @click="currentPageIndex = 3"
          v-if="currentPageIndex == 3"
        >
          <span class="arrow">></span> Products Meta</span
        >
        <span
          :class="this.breadcrumbsList[4].className"
          @click="currentPageIndex = 4"
          v-if="currentPageIndex == 4"
        >
          <span class="arrow">></span> Products Alt Text</span
        >
        <span
          :class="this.breadcrumbsList[5].className"
          @click="currentPageIndex = 5"
          v-if="currentPageIndex == 5"
        >
          <span class="arrow">></span> Collection Meta</span
        >
        <span
          :class="this.breadcrumbsList[6].className"
          @click="currentPageIndex = 6"
          v-if="currentPageIndex == 6"
        >
          <span class="arrow">></span> Blogs Meta</span
        >
        <span
          :class="this.breadcrumbsList[100].className"
          @click="currentPageIndex = 100"
          v-if="currentPageIndex == 100"
        >
          <span class="arrow">></span> Sections Meta</span
        >
      </span>
      <span
        :class="this.breadcrumbsList[7].className"
        @click="currentPageIndex = 7"
        v-if="
          currentPageIndex == 7 ||
          currentPageIndex == 8 ||
          currentPageIndex == 9 ||
          currentPageIndex == 10 ||
          currentPageIndex == 11 ||
          currentPageIndex == 12
        "
      >
        <span class="arrow">></span> On Page SEO
      </span>
      <span v-if="currentPageIndex > 7">
        <span
          :class="this.breadcrumbsList[8].className"
          @click="currentPageIndex = 8"
          v-if="currentPageIndex == 8"
        >
          <span class="arrow">></span> Home Configuration</span
        >
        <span
          :class="this.breadcrumbsList[10].className"
          @click="currentPageIndex = 10"
          v-if="currentPageIndex == 9 || currentPageIndex == 10"
        >
          <span class="arrow">></span> Collection List</span
        >
        <span
          :class="this.breadcrumbsList[12].className"
          @click="currentPageIndex = 12"
          v-if="currentPageIndex == 11 || currentPageIndex == 12"
        >
          <span class="arrow">></span> Product List</span
        >
      </span>
      <span
        :class="this.breadcrumbsList[9].className"
        @click="currentPageIndex = 9"
        v-if="currentPageIndex == 9"
      >
        <span class="arrow">></span> Collection Configuration
      </span>
      <span
        :class="this.breadcrumbsList[11].className"
        @click="currentPageIndex = 11"
        v-if="currentPageIndex == 11"
      >
        <span class="arrow">></span> Product Configuration
      </span>
    </div>
    <div class="seo-landing-page">
      <div v-if="currentPageIndex == 0">
        <sales-channels-list @pageChanged="onPageChange"></sales-channels-list>
      </div>
      <div v-else-if="currentPageIndex == 1">
        <seoMenu @pageChanged="onSamePage"></seoMenu>
      </div>
      <div v-else-if="currentPageIndex == 2">
        <seoTiles @pageChanged="onSamePage"></seoTiles>
      </div>
      <div v-else-if="currentPageIndex == 3">
        <upload-history
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></upload-history>
      </div>
      <div v-else-if="currentPageIndex == 4">
        <altTextUploadHistory
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></altTextUploadHistory>
      </div>
      <div v-else-if="currentPageIndex == 5">
        <cmUploadHistory
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></cmUploadHistory>
      </div>
      <div v-else-if="currentPageIndex == 6">
        <blogUploadHistory
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></blogUploadHistory>
      </div>
      <div v-else-if="currentPageIndex == 100">
        <sectionPageUploadHistory
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></sectionPageUploadHistory>
      </div>
      <div v-else-if="currentPageIndex == 7">
        <onPageSeo
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></onPageSeo>
      </div>
      <div v-else-if="currentPageIndex == 8">
        <homePageConfig
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></homePageConfig>
      </div>
      <div v-else-if="currentPageIndex == 9">
        <collectionConfig
          :selectedChannel="selectedChannel"
          :slug="slug"
          :uid="uid"
          @pageChanged="onSamePage"
        ></collectionConfig>
      </div>
      <div v-else-if="currentPageIndex == 10">
        <collectionList
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></collectionList>
      </div>
      <div v-else-if="currentPageIndex == 11">
        <productConfig
          :selectedChannel="selectedChannel"
          :slug="slug"
          @pageChanged="onSamePage"
        ></productConfig>
      </div>
      <div v-else-if="currentPageIndex == 12">
        <productList
          :selectedChannel="selectedChannel"
          @pageChanged="onSamePage"
        ></productList>
      </div>
    </div>
  </div>
</template>

<script>
/* File imports */
import SalesChannelsList from "./sales-channels-list.vue";
import UploadHistory from "./product-export-history.vue";
import seoMenu from "./seoMenu.vue";
import seoTiles from "./seoTiles.vue";
import altTextUploadHistory from "./product-alt-text/altText-upload-history.vue";
import cmUploadHistory from "./collection/upload-history.vue";
import blogUploadHistory from "./blogs/upload-history.vue";
import sectionPageUploadHistory from "./sections/upload-history.vue";
import onPageSeo from "./onPageSeo/on-page-seo.vue";
import homePageConfig from "./onPageSeo/home-page-config.vue";
import collectionList from "./onPageSeo/collection-list.vue";
import collectionConfig from "./onPageSeo/collection-config.vue";
import productConfig from "./onPageSeo/product-config.vue";
import productList from "./onPageSeo/product-list.vue";

/* Service imports */

export default {
  name: "LandingPage",
  components: {
    SalesChannelsList,
    UploadHistory,
    seoMenu,
    seoTiles,
    altTextUploadHistory,
    cmUploadHistory,
    blogUploadHistory,
    sectionPageUploadHistory,
    onPageSeo,
    homePageConfig,
    collectionList,
    collectionConfig,
    productConfig,
    productList,
  },
  data() {
    return {
      currentPageIndex: 0,
      selectedChannel: null,
      slug: "",
      breadcrumbsList: {
        0: { className: "bold-heading" },
        1: { className: "normal-heading" },
        2: { className: "normal-heading" },
        3: { className: "normal-heading" },
        4: { className: "normal-heading" },
        5: { className: "normal-heading" },
        6: { className: "normal-heading" },
        7: { className: "normal-heading" },
        8: { className: "normal-heading" },
        9: { className: "normal-heading" },
        10: { className: "normal-heading" },
        11: { className: "normal-heading" },
        12: { className: "normal-heading" },
        100: { className: "normal-heading" },
      },
    };
  },
  methods: {
    onPageChange(obj) {
      this.currentPageIndex = obj.currentPageIndex;
      this.selectedChannel = obj.application;
    },
    onSamePage(obj) {
      this.currentPageIndex = obj.currentPageIndex;
      this.slug = obj?.slug;
      this.uid = obj?.uid;
    },
  },
  watch: {
    currentPageIndex(value) {
      for (let i = 0; i <= 12; i++) {
        if (i == value) {
          this.breadcrumbsList[value].className = "bold-heading";
        } else {
          this.breadcrumbsList[i].className = "normal-heading";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.seo-landing-page {
  font-family: "Inter";
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}
.breadcrums {
  margin-left: 24px;
}
.normal-heading {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
  cursor: pointer;
}
// .normal-heading:hover {
//   color: black;
// }
.bold-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  color: black;
  cursor: pointer;
}
.arrow {
  font-weight: 400;
  font-size: 14px;
  color: #9b9b9b;
  margin: 0 8px 0 8px;
}
.hidden {
  visibility: hidden;
}
</style>
