<template>
    <div class="product-list">
      <div v-if="details?.length > 0 || searchedDetails?.length > 0 || pageLoading" class="content">
        <div class="title">
          <div class="page-title">Products SEO Listing</div>
          <div class="below-title">Select a product to configure SEO content</div>
        </div>
        <div class="history-filter">
          <nitrozen-input
            type="search"
            placeholder="Search By Product name"
            :showSearchIcon="true"
            @input="onProductSearch"
            v-model="searchProduct"
            class="history-search"
          ></nitrozen-input>
        </div>
        <nitrozen-pagination
          style="margin-top:10px"
          v-if="paginated_details.length > 0 && !pageLoading"
          name="Result"
          v-model="pagination"
          @change="setPagination"
          :pageSizeOptions="[5, 10, 20, 50]"
        ></nitrozen-pagination>
        <div class="seo-history-container">
          <div
            @click="clicked(11, data.slug)"
            :key="data"
            class="seo-history-box"
            v-for="data of paginated_details"
          >
            <div class="channel-details">
              <div class="line-1">
                <span>
                  {{ `${data.name}` }}
                </span>
              </div>
             <div class="product-data">
              <div class="line-2">
                <p class="field">{{ "Created by " }}</p>
                <p class="value">{{ `${data.created_by}` }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Created on " }}</p>
               <p class="value">{{ getFormattedDate(data.created_on) }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Modified by " }}</p>
                <p class="value">{{ `${data.modified_by}` }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Modified on " }}</p>
                <p class="value">{{ getFormattedDate(data.modified_on) }}</p>
              </div>
             </div>
              <div class="seo-list-btn-cont"></div>
            </div>
            <div class="action-btns">
              <div class="history-status">
                <div v-if="data.isActive == true" class="status-success">
                  ACTIVE
                </div>
                <div v-else class="status-error">
                  INACTIVE
                </div>
              </div>
            </div>
          </div>
        </div>
        <nitrozen-pagination
          style="margin-top:10px"
          v-if="paginated_details.length > 0 && !pageLoading"
          name="Result"
          v-model="pagination"
          @change="setPagination"
          @searchInputChange="debouncedCategorySearch"
          :pageSizeOptions="[5, 10, 20, 50]"
        ></nitrozen-pagination>
      
      </div>
      <div v-if="pageLoading">
        <loader></loader>
      </div>
      <div v-if="details?.length == 0 && !pageLoading" class="content">
            <div class="no-history">
              <div class="title">
                  <span>List of Products</span>
              </div>
              <div class="seo-no-history-box">
                  <img src="./../../assets/empty.png" alt="No product to show!">
                  <span class="page-title">You haven't created any Products SEO yet!</span>
                  <p class="below-title">Once you create a Product SEO, it will apppear in the here.</p>
              </div>
            </div>
          </div>
          <div v-if="(details?.length > 0 || searchedDetails?.length > 0) && paginated_details?.length == 0 && !pageLoading" class="content">
            <div class="no-history">
              <div class="seo-no-history-box">
                  <img src="./../../assets/empty.png" alt="No product to show!">
                  <span class="page-title">No Product Found!</span>
              </div>
            </div>
          </div>
        </div>  
    </template>
  
  <script>
  import {
    NitrozenInput,
    NitrozenPagination,
  } from "@gofynd/nitrozen-vue";
  import MainService from '../../services/main-service';
  import Loader from "../../components/loader.vue";
  import { debounce } from "../../helper/utils";
  
  const PAGINATION = {
    limit: 10,
    current: 1,
    total: `0`,
  };
  const MONTHS = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  export default {
    data() {
      return {
        details:[],
        product_data:[],
        paginated_details:[],
        pageLoading: false,
        pagination: { ...PAGINATION },
        searchProduct:'',
        searched_collection:[],
        currentPage: PAGINATION.current,
        currIndex: 0,
        searchedDetails:[],
        searchedPageId:'',
        has_next: false
      };
    },
    components:{
      NitrozenInput,
      NitrozenPagination,
      Loader
    },
    mounted(){
      this.getAllProductsData();
    },
    props:{
      selectedChannel: {
        type: Object,
      },
      scriptStatus: {
        type: Boolean
      }
    },
    methods: {
      clicked(item, slug) {
        this.$emit("pageChanged", { currentPageIndex: item, slug: slug, scriptStatus: this.scriptStatus });
      },
      setPagination(updatedPagination) {
          this.pagination = Object.assign({}, this.pagination, updatedPagination);
          this.currIndex = this.pagination.limit * (this.pagination.current - 1);
          this.currentPage = updatedPagination.current;
        },
          debouncedCategorySearch : debounce(function(search){
      this.onProductSearch(search)
    }, 2000),
    onProductSearch() {
      this.pagination = {...PAGINATION};
      this.searchProducts(this.searchProduct.toLowerCase());
    },
    async searchProducts(query) {
      this.paginated_details = [];
      this.pageLoading = true;
      console.log('current page',this.currentPage);
      return await MainService.getAllProducts({ app: this.selectedChannel, q:query, limit: 10, pageNo: this.currentPage })
        .then(({ data }) => {
          console.log('data',data);
        this.paginated_details = [];
         if(data?.data){
          data?.data?.map((product) => {  
            console.log('product',product);
              this.paginated_details.push({
                collection_id: product.uid,
                name: product.name,
                slug: product.slug,
                isActive: product?.is_active,
                created_on: product?.created_on,
                created_by: product?.created_by?.username,
                modified_by: product?.modified_by?.username,
                modified_on: product?.modified_on  
              });
            });
         }
            this.pageLoading = false;
            //storing all serached products in Array
            this.searchedDetails = [...this.searchedDetails, ...this.paginated_details];

            this.has_next = data?.page?.has_next;
            this.pagination.total = data?.page?.item_total; 
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching products`);
        }).finally(() =>{
          this.pageLoading = false;
        });
    },
      getFormattedDate(date) {
        let d = new Date(date);
        let newDate = `${
          MONTHS[d.getMonth()]
        } ${d.getDate()}, ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${
          d.getHours() >= 12 ? "PM" : "AM"
        }`;
        return newDate;
      },
      async getAllProductsData() {
        this.pageLoading = true;
        this.paginated_details = [];
         await MainService.getAllProducts({pageNo: this.currentPage, limit: this.pagination.limit,
          app: this.selectedChannel
        })
          .then(({ data }) => {
            console.log('data',data);
        this.paginated_details = [];
     if(data?.data){
      data?.data?.map((product) => { 
        console.log('product',product);    
              this.paginated_details.push({
                collection_id: product?.uid,
                name: product?.name,
                slug: product?.slug,
                isActive: product?.is_active,
                created_on: product?.created_on,
                created_by: product?.created_by?.username,
                modified_by: product?.modified_by?.username,
                modified_on: product?.modified_on
              });
            });
     }
            this.pageLoading = false;
            //storing all products in details Array
            this.details = [...this.details, ...this.paginated_details];

            // this.currentPage = data?.page?.current;
            this.has_next = data?.page?.has_next;
            this.pagination.total = data?.page?.item_total;
          })
          .catch((err) => {
            this.$snackbar.global.showError(`${err} while fetching products`);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },
    },
      watch:{
        currentPage(newValue, oldValue){
        if(this.searchProduct?.length > 0){
          if(newValue > oldValue){
            this.searchProducts(this.searchProduct);
          }else{
             this.paginated_details = this.searchedDetails.slice( 
              this.currIndex,
            this.currIndex + this.pagination.limit   
          );
          }
        }else{
          if(newValue > oldValue){
            this.getAllProductsData();
          }else{
             this.paginated_details = this.details.slice( 
              this.currIndex,
            this.currIndex + this.pagination.limit   
          );
          }
        }
        }
      }
  };
  </script>
  
  <style lang="less" scoped>
  .product-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
  }
  .title {
    display: flex;
    float: left;
    flex-direction: column;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
  }
  
  .below-title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #666666;
  }
  .content {
    display: flex;
    flex-direction: column;
    background: white;
    margin: 16px 0px;
    padding: 24px;
    border-radius: 10px;
  
    .page-title {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      color: #41434c;
    }
  
    .history-filter {
      display: flex;
      align-items: center;
      padding: 12px;
      margin-bottom: 16px;
      width: auto;
      height: auto;
  
      background: #f8f8f8;
      border-radius: 4px;
    }
    .history-search {
      box-sizing: border-box;
  
      width: 100%;
      height: 40px;
  
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    .seo-history-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
      .no-history {
        color: #9b9b9b;
        font-size: 12px;
        text-align: center;
      }
  
      .seo-list-btn-cont {
        display: flex;
        justify-content: flex-end;
      }
    }
  
    .seo-history-box {
      display: flex;
    width: 100%;
    height: auto;
    background-color: white;
    border: 1px solid #e4e5e6;
    padding: 17px;
    border-radius: 4px;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  
      & + .seo-history-box:nth-child(3n + 1) {
        margin-left: 0;
      }
  
      .channel-details {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
      .product-data{
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }
  
      .action-btns {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-left: auto;
      }
  
      .history-status {
        margin-left: auto;
      }
  
      .status-success {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #0a5f23;
        padding: 1px 10px;
        margin-left: auto;
        border: 1px solid #0a5f23;
        border-radius: 2px;
        height: 21px;
        text-transform: uppercase;
      }
  
      .status-error {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #9b9b9b;
  
        padding: 1px 10px;
        margin-left: auto;
        border: 1px solid #9b9b9b;
        border-radius: 2px;
        height: 21px;
        text-transform: uppercase;
      }
  
      .line-1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        display: flex;
      }
  
      .line-2 {
        display: flex;
        align-items: baseline;
        gap: 8px;
      }
  
      .field{
        color: #9B9B9B;
        font-size: 10px;
      }
      
      .value{
        color: #41434C;
        font-size: 12px;
      }
    }
  
    .seo-no-history-box{
        display: flex;
      width: 100%;
      height: auto;
      background-color: white;
      margin-bottom: 16px;
      flex-direction: column;
      align-items: center;
      }
  }
  </style>
  