<template>
  <div>
    <div class="fullscreen-overlay"></div>
    <textarea ref="editor"></textarea>
  </div>
</template>

<script>
import SunEditor from "suneditor";
import { formatBlock, table, image, imageGallery } from "suneditor/src/plugins";
export default {
  data() {
    return {
      isUserInput: false,
      limitedContent: "",
      wordLimit: 1500,
      editorOptions: null,
      isFullScreen: false,
    };
  },
  props: {
    content: {
      type: String,
    },
  },
  watch: {
    content() {
      {
        if (!this.isUserInput && this.content) {
          this.editor.setContents(this.content);
          this.isUserInput = true;
        }
      }
    },
    isFullScreen(value) {
      if (value) {
        this.activateOverlay();
      } else {
        this.deactivateOverlay();
      }
    },
  },

  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      this.editorOptions = {
        // SunEditor options here
        width: "100%",
        content: "",
        plugins: [formatBlock, table, image, imageGallery],
        buttonList: [
          ["formatBlock", "bold", "italic", "strike", "fullScreen"],
          ["table", "image" /**"video", "audio"  ,'math' */],
        ],
        formats: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
        showBottom: false,
        placeholder: "Enter your text here",
        resizingBar: true,
        maxCharCount: 20000,
        fullScreenOffset: "50px",
        maxHeight: "400px",
        // table,
        table: {
          addRowPosition: "bottom",
          addRowStyle: "background-color:#f5f5f5;",
          td: {
            backgroundColor: "#fff",
            minWidth: "100px",
          },
          th: {
            backgroundColor: "#fff",
            minWidth: "100px",
          },
        },
        image: {
          multipleFile: true,
          uploadUrl: "your-image-upload-url",
          // other image options
        },
      };

      // Initialize SunEditor
      this.editor = SunEditor.create(this.$refs.editor, this.editorOptions);
      if (this.content) {
        this.editor.setContents(this.content);
      }
      const self = this;
      this.editor.toggleFullScreen = function (isFullScreen, core) {
        console.log(core);
        self.isFullScreen = isFullScreen;
        let suneditor = document.querySelector(".sun-editor");
        if (isFullScreen) {
          if (suneditor) {
            suneditor.style.margin = "50px";
            suneditor.style.width = "95%";
            suneditor.style.height = "85%";
            suneditor.style.overflow = "hidden";
            suneditor.style.top = "-20px";
            suneditor.style.left = "-18px";
            suneditor.style.zIndex = "100";
          }
        } else {
          if (suneditor) {
            suneditor.style.margin = "";
            suneditor.style.width = "";
            suneditor.style.height = "";
            suneditor.style.overflow = "";
            suneditor.style.top = "";
            suneditor.style.left = "";
            suneditor.style.zIndex = "";
          }
        }
      };
      this.editor.onChange = (value) => {
        this.$emit("change", value);
      };
    },
    activateOverlay() {
      const overlay = document.querySelector(".fullscreen-overlay");
      if (overlay) {
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      }
    },
    deactivateOverlay() {
      const overlay = document.querySelector(".fullscreen-overlay");
      if (overlay) {
        overlay.style.backgroundColor = "transparent";
      }
    },
  },
  beforeUnmount() {
    // Destroy the editor instance when the component is unmounted
    this.editor.destroy();
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none; /* Allow interactions with elements below */
  z-index: 6;
}
.sun-editor-editable h1 {
  -webkit-margin-before: unset !important;
  -webkit-margin-after: unset !important;
}
</style>
