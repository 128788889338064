import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import NitrozenSnackBar from "@gofynd/nitrozen-vue/src/components/NSnackbar";
import './assets/css/suneditor.min.css';

Vue.config.productionTip = false;
Vue.use(NitrozenSnackBar);
Vue.snackbar.register("show", (message) => message, {
  position: "top-center",
  duration: 3000,
});
Vue.snackbar.register("showSuccess", (message) => message, {
  position: "top-center",
  duration: 3000,
  type: "success",
});
Vue.snackbar.register("showError", (message) => message, {
  position: "top-center",
  duration: 3000,
  type: "error",
});
Vue.snackbar.register("showWarning", (message) => message, {
  position: "top-center",
  duration: 3000,
  type: "warning",
});
Vue.snackbar.register("showInfo", (message) => message, {
  position: "top-center",
  duration: 3000,
  type: "info",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  NitrozenSnackBar,
}).$mount("#app");
