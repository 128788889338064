<template>
  <div class="home">
    <div class="home-config">
      <div class="config-heading">
        <span class="config-heading1">Home Page Configuration</span>
        <span class="config-heading2"
          >Configure the SEO content for your homepage, including the title tag,
          meta description, and content</span
        >
      </div>
      <div class="save-button">
        <button
          class="save-btn"
          type="button"
          name="save-content"
          @click="saveTheContent"
        >
          Save
        </button>
      </div>
    </div>
    <div class="top">
      <div class="onpage-content">
        <div class="content-heading">
          <span>On-Page SEO Content</span>
        </div>
        <div
          class="hr-line"
          style="color: #e0e0e0; margin: 0 -16px 0 -16px; gap: unset"
        >
          <hr />
        </div>
        <div class="toggle-btn">
          <div class="help-txt">
            <span class="lower-heading">Desktop</span>
            <span
              data-toggle="tooltip"
              title="Enable the toggle button for desktop view"
              ><img src="../../assets/help.svg"
            /></span>
          </div>
          <NitrozenToggleBtn
            id="n-toggle-keya1k9ub"
            showIcon
            v-model="isDesktop"
            :helperText="true"
            @change="desktopToggle"
          >
          </NitrozenToggleBtn>
        </div>
        <div class="toggle-btn">
          <div class="help-txt">
            <span class="lower-heading">Mobile</span>
            <span
              data-toggle="tooltip"
              title="Enable the toggle button for mobile view"
              ><img src="../../assets/help.svg"
            /></span>
          </div>
          <NitrozenToggleBtn
            id="n-toggle-keya1k9ub"
            showIcon
            v-model="isMobile"
            :helperText="true"
            @change="mobileToggle"
          >
          </NitrozenToggleBtn>
        </div>
        <div id="text-editor">
          <div class="lower-heading">SEO Content</div>
          <div>
            <SunEditor
              :content="content"
              v-model="content"
              @change="contentReciever"
            ></SunEditor>
          </div>
        </div>
        <div class="color-box">
          <div class="help-txt">
            <span class="background-color font-prop">Background Color</span>
            <span
              data-toggle="tooltip"
              title="Use a contrasting colour for the text to make it easy to read"
              ><img src="../../assets/help.svg"
            /></span>
          </div>
          <div class="config-color-picker" lable="Text Color">
            <color-picker
              :color="bgClr"
              @color-change="bgClr = $event"
            ></color-picker>
          </div>
        </div>
        <div class="color-box">
          <div class="help-txt">
            <span class="text-color font-prop">Text Color</span>
            <span
              data-toggle="tooltip"
              title="Use a light colour for the background to make the text stand out"
              ><img src="../../assets/help.svg"
            /></span>
          </div>
          <div class="config-color-picker" lable="Text Color">
            <color-picker
              :color="textClr"
              @color-change="textClr = $event"
            ></color-picker>
          </div>
        </div>
        <div class="custom-css">
          <div class="title-btn">
            <div>
              <span class="lower-heading">Custom CSS</span>
            </div>
            <div
              @mouseover="isHovered = true"
              @mouseleave="isHovered = false"
            >
              <div class="info-container">
                <img
                  v-show="isHovered"
                  src="./../../assets/hover_box.svg"
                  alt="hover-box"
                  class="hover-box"
                />
                <span v-show="isHovered" class="info"
                  >Click to apply default css style to your SEO content</span
                >
              </div>
              <button @click="setToDefault" class="reset-btn">Default</button>
            </div>
          </div>
          <textarea
            style="
              background-color: black;
              color: white;
              height: 150px;
              width: 98%;
            "
            v-model="customCSS"
            class="css"
            @input="updateStyle"
          ></textarea>
        </div>
      </div>
      <div v-if="mode === 'desktop'" class="live-preview-container">
        <div class="live-pre-header">
          <div class="icons">
            <div
              style="
                background-color: #f2f2f2;
                padding: 5px;
                border: 1px solid #e0e0e0;
                border-radius: 2px;
              "
              @click="changeMode('des')"
            >
              <img src="./../../assets/laptop.svg" />
            </div>
            <div @click="changeMode('mob')">
              <img src="./../../assets/phone.svg" />
            </div>
          </div>
          <div class="live-preview-title">live preview</div>
        </div>
        <div class="preview-content">
          <div class="shapes">
            <img src="./../../assets/desktop_url_box.svg" alt="search-box" />
          </div>
          <div class="box-size" style="height: 400px; overflow: auto">
            <div
              class="main"
              :style="'background-color:' + bgClr + ';color:' + textClr"
            >
              <div
                class="seo-content se-wrappper-wysiwyg sun-editor-editable"
                v-html="previewContent"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mode === 'mobile'" class="live-preview-container">
        <div class="live-pre-header">
          <div class="icons">
            <div @click="changeMode('des')">
              <img src="./../../assets/laptop.svg" />
            </div>
            <div
              style="
                padding: 5px;
                background-color: #f2f2f2;
                border: 1px solid #e0e0e0;
                border-radius: 2px;
              "
              @click="changeMode('mob')"
            >
              <img src="./../../assets/phone.svg" />
            </div>
          </div>
          <div class="live-preview-title">live preview</div>
        </div>
        <div class="centre-mob">
          <div class="preview-content-mob">
            <div class="shapes-mob">
              <img src="./../../assets/mobile_url_box.svg" alt="search-box" />
            </div>
            <div
              class="box-size"
              style="height: 400px; max-width: 300px; overflow: auto"
            >
              <div
                class="main"
                :style="'background-color:' + bgClr + ';color:' + textClr"
              >
                <div
                  class="seo-content se-wrappper-wysiwyg sun-editor-editable"
                  v-html="previewContent"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NitrozenToggleBtn } from "@gofynd/nitrozen-vue";
import ColorPicker from "../../components/color-picker.vue";
import SunEditor from "./../../components/SunEditor.vue";
import MainService from "../../services/main-service";
export default {
  components: {
    NitrozenToggleBtn,
    ColorPicker,
    SunEditor,
  },
  mounted() {
    this.application_id = this.selectedChannel?.id;
    this.getHomePageConfig();
    // this.contentReciever();
  },
  props: {
    selectedChannel: {
      type: Object,
    }
  },
  methods: {
    contentReciever(value) {
      this.content = value;
    this.previewContent = value;
    this.handlePreviewContentChange();
    },
    channelClicked(item) {
      this.$emit("pageChanged", { currentPageIndex: item});
    },
    handlePreviewContentChange() {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = this.previewContent;
      const anchorTags = tempElement.querySelectorAll("a");
      if (anchorTags) {
        anchorTags.forEach((anchorTag) => {
          anchorTag.setAttribute("target", "_blank");
        });
      }
      this.previewContent = tempElement.innerHTML;
    },
    setToDefault() {
      this.customCSS = this.defaultCSS;
      this.updateStyle();
    },
    updateStyle() {
      const styleTagId = "homeCustomCSS";
      let styleTag = document.getElementById(styleTagId);

      if (!styleTag) {
        styleTag = document.createElement("style");
        styleTag.id = styleTagId;
        styleTag.innerHTML = this.customCSS;
        document.head.appendChild(styleTag);
      }

      styleTag.innerHTML = this.customCSS;
      // Trigger a reflow to apply the updated styles
      void styleTag.offsetWidth;
    },
    injectStyle() {
      const styleElement = document.createElement("style");
      styleElement.setAttribute("id", "homeCustomCSS");
      styleElement.innerHTML = this.customCSS;

      document.head.appendChild(styleElement);
    },
    async saveTheContent() {
      const data = {
        company_id: this.selectedChannel?.company_id,
        application_id: this.application_id,
        isDesktop: this.isDesktop,
        isMobile: this.isMobile,
        seoContent: this.previewContent,
        bgColor: this.bgClr,
        textColor: this.textClr,
        customCSS: this.customCSS,
      };
      return await MainService.saveHomePageConfig(data)
        .then(() => {
          this.$snackbar.global.showSuccess("saved successfully");
          this.channelClicked(7);
        })
        .catch((err) => {
          this.$snackbar.showError(`${err.message}`);
        });
    },
    changeMode(item) {
      if (item == "mob") {
        this.mode = "mobile";
      } else {
        this.mode = "desktop";
      }
    },
    async getHomePageConfig() {
      this.customCSS = this.defaultCSS;
      return await MainService.getHomePageConfig(this.application_id)
        .then((res) => {
          this.isDesktop = res?.data.data?.isDesktop;
          this.isMobile = res?.data.data?.isMobile;
          this.content = res?.data.data?.seoContent;
          this.bgClr = res?.data.data?.bgColor;
          this.textClr = res?.data.data?.textColor;
          this.customCSS = res?.data.data?.customCSS || this.defaultCSS;
          this.previewContent = res?.data.data?.seoContent;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.injectStyle();
        });
    },
  },
  data() {
    return {
      content: "",
      previewContent: "",
      textClr: "#000000",
      bgClr: "#ffffff",
      mode: "desktop",
      isDesktop: false,
      isMobile: false,
      isHovered: false,
      application_id: "",
      customCSS: "",
      defaultCSS: `
.seo-content {
    margin: 5px;
}`,
    };
  }
};
</script>

<style scoped>
.home {
  padding: 24px;
  border-radius: 10px;
  background-color: #fff;
}

.top {
  grid-template-columns: 35% 65%;
  width: calc(100% - 24px);
  display: grid;
  gap: 24px;
}

.home-config {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.config-heading {
  display: flex;
  flex-direction: column;
}

.config-heading1 {
  width: 595px;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #41434c;
}

.config-heading2 {
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #666666;
}

.onpage-content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  height: auto;
  width: inherit;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.content-heading {
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.toggle-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.color-box {
  margin-bottom: 16px;
}

.lower-heading {
  height: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #4f4f4f;
}

.live-preview-container {
  position: relative;
  height: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0 16px 16px 16px;
}

.preview-content {
  overflow: hidden;
  height: fit-content;
  white-space: pre-wrap;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.centre-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview-content-mob {
  overflow: hidden;
  width: 42%;
  height: fit-content;
  white-space: pre-wrap;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.shapes > img {
  width: 100%;
}
.shapes-mob > img {
  border-radius: 8px 8px 0 0;
  border: 1px solid #e0e0e0;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.save-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 16px;
  gap: 10px;
  width: auto;
  height: 40px;
  background: #2e31be;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.live-preview-title {
  height: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2e31be;
  padding: 12px 16px;
}

.font-prop {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
}

.live-pre-header {
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 12px;
  cursor: pointer;
}

.help-txt {
  display: flex;
  gap: 8px;
}
.custom-css {
  display: table-caption;
}
.title-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.reset-btn {
  height: fit-content;
  border-radius: 4px;
  cursor: pointer;
}
.reset-btn:hover {
  background-color: rgb(39, 163, 246);
}
.info-container {
  position: relative;
}
.hover-box {
  position: absolute;
  z-index: 1;
  width: 300px;
  bottom: -5px;
  left: -41px;
}
.info {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  position: absolute;
  z-index: 1;
  bottom: 14px;
  width: 245px;
  left: -15px;
  color: white;
}

#text-editor {
  text-align: justify !important;
  margin-bottom: 16px;
}
.seo-content {
  background-color: unset !important;
  color: unset !important;
  /* line-break: anywhere; */
  overflow-wrap: break-word !important;
  width: unset !important;
  height: unset !important;
  text-align: justify !important;
}
.sun-editor-editable {
  background-color: unset !important;
  line-height: normal;
  font-family: unset !important;
  padding: 2px;
}
.sun-editor-editable h1,h2,h3,h4,h5,h6{
-webkit-margin-before: unset !important;
-webkit-margin-after: unset !important;
}
</style>
