<template>
  <div>
    <section>
      <div class="container-box">
        <div class="icon">
          <img src="../assets/paper.svg" />
        </div>

        <div class="content">
          <section class="label-text">
            {{ fileName }}
            <section>
              <img
              class="action"
              @click="$emit('click')" 
              src="../assets/cross.svg" />
            </section>
          </section>
          <section v-if="!uploaded" class="loader">
            <section
              class="flow"
              :style="{ width: `${completeCount}%` }"
            ></section>
          </section>
          <section v-if="!uploaded" class="label-text">
            <section class="download-text">
              {{ uploadedSize }} / {{ totalSize }}
            </section>
            <section class="complete-text">
              {{ completeCount }}% complete
            </section>
          </section>
          <section v-else class="success">
            <img src="../assets/checkmark.svg" alt="checkmark" width="20px">
            <span>Upload Completed</span>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "ProgressBar",
  data() {
    return {
      growth: 0,
    };
  },
  props: {
    completeCount: {
      type: Number,
      default: 0,
    },
    totalSize: {
      type: String,
      default: "",
    },
    uploadedSize: {
      type: String,
      default: "",
    },
    fileName: {
      type: String,
      default: "default.csv",
    },
    uploaded : {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="less" scoped>
.container-box {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .label-text {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
  }
  .success {
    color: #0A5F23;
    display: flex;
    align-items: center;
    padding: 0.3;
    font-size: 13px;
    font-weight: 600;
    padding: 0.3;
    gap: 0.4rem;
  }
  .action{
    cursor: pointer;
  }

  .download-text {
    color: gray;
  }
  .complete-text {
    color: blue;
  }
  .icon {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7eeff;
  }
  .content {
    flex-grow: 2;
    padding: 0 0.6rem;
  }
  .loader {
    height: 6px;
    background-color: gray;
    border-radius: 10px;
    position: relative;
  }
  .flow {
    background-color: blue;
    position: absolute;
    z-index: 1;
    height: 100%;
    border-radius: 10px;
    max-width: 100%;
  }
}
</style>
