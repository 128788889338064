"use strict";
var svgs = {};
svgs["long-left-arrow"] =
  '<svg width="20" height="20" fill="#2e31be" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="prefix__Link-Shortner" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="prefix__Deeplink_Create" transform="translate(-24 -12)"><g id="prefix__Group-4"><g id="prefix__Group" transform="translate(24 12)"><g id="prefix__Group-12"><g id="prefix__Group-10"><path id="prefix__Rectangle" fill-opacity=".01" fill="#FFF" d="M0 0h24v24H0z"></path><g id="prefix__Group-7" transform="translate(2.4 4.8)" stroke="#2e31be" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path id="prefix__Stroke-1" d="M7.6 14.8.4 7.6"></path><path id="prefix__Stroke-3" d="M7.6.4.4 7.6"></path><path id="prefix__Stroke-5" d="M18.8 7.6H.4"></path></g></g></g></g></g></g></g></svg>';
svgs["user"] =
  '<svg xmlns="http://www.w3.org/2000/svg" fill="#b2b3d4" width="17" height="17" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>';
svgs["edit"] =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 64 64" height="22px" id="Layer_1" version="1.1" viewBox="0 0 64 64" width="40px" xml:space="preserve"><g><path fill="#9B9B9B" d="M55.736,13.636l-4.368-4.362c-0.451-0.451-1.044-0.677-1.636-0.677c-0.592,0-1.184,0.225-1.635,0.676l-3.494,3.484   l7.639,7.626l3.494-3.483C56.639,15.998,56.639,14.535,55.736,13.636z"/><polygon fill="#9B9B9B" points="21.922,35.396 29.562,43.023 50.607,22.017 42.967,14.39  "/><polygon fill="#9B9B9B" points="20.273,37.028 18.642,46.28 27.913,44.654  "/><path fill="#9B9B9B" d="M41.393,50.403H12.587V21.597h20.329l5.01-5H10.82c-1.779,0-3.234,1.455-3.234,3.234v32.339   c0,1.779,1.455,3.234,3.234,3.234h32.339c1.779,0,3.234-1.455,3.234-3.234V29.049l-5,4.991V50.403z"/></g></svg>';
svgs["arrow-down"] =
  '<svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="prefix__arrow-dropdown-black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="prefix__Group-2" transform="rotate(90 12 12)"><path id="prefix__Rectangle" d="M0 0h24v24H0z"/><g id="prefix__Line-+-Line-Copy-4" transform="rotate(90 4 12)" stroke="#aeaeb1" stroke-linecap="round"><path d="M.237 5.706 4.5 1.039" id="prefix__Line"/><path d="m4.5 5.706 4.263-4.667" id="prefix__Line-Copy-4" transform="matrix(-1 0 0 1 13.263 0)"/></g></g></g></svg>';
svgs["automate"] =
  '<svg width="86" height="86" viewBox="0 0 86 86" xmlns="http://www.w3.org/2000/svg"><g id="prefix__Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="prefix__Currencies-Copy-2"><path id="prefix__Rectangle-Copy-14" fill-opacity=".01" fill="#FFF" d="M0 0h86v86H0z"/><g id="prefix__Group-9" transform="translate(23 22)" stroke="#4D4D4E" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M5.493 9.229 3.487 5.893l2.406-2.407 3.335 2.007v.004a9.083 9.083 0 0 1 2.042-.85l.94-3.778h3.404l.941 3.777a9.05 9.05 0 0 1 2.042.85v-.003l3.336-2.007 2.406 2.407-2.005 3.336h-.005a8.97 8.97 0 0 1 .85 2.042l3.778.94v3.404l-3.778.94a9.003 9.003 0 0 1-.85 2.043h.005l2.005 3.335-2.406 2.405-3.336-2.005v-.005a8.97 8.97 0 0 1-2.042.85l-.94 3.778H12.21l-.941-3.778a9.003 9.003 0 0 1-2.042-.85v.005l-3.335 2.005-2.406-2.405 2.006-3.335h.005a9.003 9.003 0 0 1-.85-2.042L.87 15.615v-3.404l3.777-.94a8.97 8.97 0 0 1 .85-2.042h-.004z" id="prefix__Stroke-1"/><path d="M18.26 13.913a4.347 4.347 0 1 1-8.695 0 4.347 4.347 0 1 1 8.696 0z" id="prefix__Stroke-3"/><path d="m24.82 29.052-1.337-2.224 1.605-1.605 2.226 1.335-.002.004c.425-.24.877-.43 1.362-.565l.626-2.519h2.27l.626 2.519c.485.135.937.325 1.361.565l-.001-.004 2.226-1.335 1.605 1.605-1.337 2.224-.004-.001c.242.424.43.878.565 1.361l2.52.628v2.268l-2.52.628a6.028 6.028 0 0 1-.565 1.362l.004-.002 1.337 2.224-1.605 1.605-2.226-1.337.001-.003a6 6 0 0 1-1.361.566l-.626 2.519H29.3l-.626-2.519a6 6 0 0 1-1.362-.566l.002.003-2.226 1.337-1.605-1.605 1.337-2.224.003.002a6.028 6.028 0 0 1-.565-1.362l-2.518-.628V31.04l2.518-.628c.136-.483.324-.937.565-1.361l-.003.001z" id="prefix__Stroke-5"/><path d="M33.334 32.174a2.898 2.898 0 1 1-5.798 0 2.898 2.898 0 1 1 5.798 0z" id="prefix__Stroke-7"/></g></g></g></svg>';
svgs["delete"] =
  '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="prefix__delete" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="prefix__Imported-Layers" transform="translate(6 4)" stroke="#9B9B9B"><path d="M11.018 4.206 9.787 14.379a1.11 1.11 0 0 1-1.073.95H2.973a1.11 1.11 0 0 1-1.073-.95L.688 4.34" id="prefix__Stroke-1"/><path d="M3.457 2.38c0-1.229 1.068-2.226 2.385-2.226 1.317 0 2.385.997 2.385 2.227" id="prefix__Stroke-3"/><path d="M.013 2.87H11.67" id="prefix__Stroke-2"/><path d="M7.577 4.726 6.96 13.8M3.902 4.77l.616 9.075" id="prefix__Stroke-4"/></g></g></svg>';
svgs["download"] =
  '<svg height="24" viewBox="0 -16 512 512" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M413.492 128.91C396.2 42.145 311.844-14.172 225.078 3.121 161.618 15.77 111.996 65.36 99.308 128.813 37.79 135.903-6.34 191.52.747 253.043c6.524 56.621 54.48 99.34 111.477 99.3h80.093c8.848 0 16.02-7.171 16.02-16.019 0-8.847-7.172-16.02-16.02-16.02h-80.093c-44.239-.261-79.883-36.331-79.625-80.566.261-44.238 36.332-79.886 80.57-79.625 8.164 0 15.023-6.14 15.922-14.258 8.133-70.304 71.722-120.707 142.031-112.574 59.11 6.836 105.738 53.465 112.574 112.574 1.344 8.262 8.5 14.313 16.867 14.258 44.239 0 80.098 35.86 80.098 80.098 0 44.234-35.86 80.094-80.098 80.094H320.47c-8.848 0-16.02 7.172-16.02 16.02 0 8.847 7.172 16.019 16.02 16.019h80.097c61.926-.387 111.817-50.903 111.434-112.828-.352-56.395-42.531-103.754-98.508-110.606zm0 0"/><path d="m313.02 385.184-40.61 40.62V224.192c0-8.847-7.172-16.02-16.015-16.02-8.848 0-16.02 7.173-16.02 16.02v201.614l-40.61-40.621c-6.144-6.368-16.288-6.543-22.652-.395-6.363 6.145-6.539 16.285-.394 22.649.133.136.261.265.394.394l67.938 67.953a16.077 16.077 0 0 0 5.176 3.461 15.831 15.831 0 0 0 12.335 0 16.018 16.018 0 0 0 5.172-3.46l67.938-67.954c6.363-6.144 6.539-16.285.394-22.648-6.148-6.364-16.289-6.54-22.652-.395-.133.129-.266.258-.394.395zm0 0"/></svg>';
svgs["log-preview"] =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 20 12.015 20C4.835 20 0 11.551 0 11.551S4.446 4 12.015 4C19.709 4 24 11.551 24 11.551zM17 12c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>';
svgs["refresh"] =
  '<svg id="prefix__Capa_1" height="24" viewBox="0 0 551.13 551.13" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M482.239 310.011c0 113.966-92.707 206.674-206.674 206.674S68.891 423.977 68.891 310.011c0-102.208 74.639-187.086 172.228-203.562v65.78l86.114-86.114L241.119 0v71.641C124.466 88.443 34.446 188.78 34.446 310.011c0 132.955 108.164 241.119 241.119 241.119s241.119-108.164 241.119-241.119z"/></svg>';
svgs["tick"] =
  '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.491 1.75c-4.83 0-8.741 3.92-8.741 8.75s3.911 8.75 8.741 8.75c4.839 0 8.759-3.92 8.759-8.75s-3.92-8.75-8.759-8.75z" fill="#d4f1e0"/><path d="m9.084 12.532-2.088-2.088-.696.696 2.784 2.784 5.966-5.966-.696-.696-5.27 5.27z" fill="#479754"/></svg>';
svgs["menu-icon"] =
  '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 8" fill="none"><path d="M13 1L7.14142 6.85858C7.06332 6.93668 6.93668 6.93668 6.85858 6.85858L1 0.999999" stroke="black" stroke-width="2" stroke-linecap="round"/></svg>';
svgs["wish-icon"] =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>';
svgs["setting-label-icon"] =
  '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"><g id="info_24px"><path id="icon/action/info_24px" fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47998 2 2 6.48 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V9H13V7H11ZM11 11V17H13V11H11ZM4 12C4 16.41 7.58997 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.58997 4 4 7.59 4 12Z" fill="black" fill-opacity="0.54"/></g></svg>';
module.exports = svgs;
