<template>
  <div class="history-modal">
    <div class="history-modal-container">
      <div class="modal-title">
        {{ `Job ID: ${historyDetails._id}` }}
        <div class="close-icon">
          <img
            src="../../assets/close-icon.png"
            class="close"
            @click="onClose"
          />
        </div>
      </div>
      <div class="upload-details">
        <div class="column">
          <span class="col-title"> Uploaded On </span>
          <span>
            {{ historyDetails.created_at }}
          </span>
        </div>
        <div style="margin-left: 30px" class="column">
          <span class="col-title"> Uploaded By </span>
          <span>
            {{
              historyDetails &&
              historyDetails.uploaded_by &&
              `${historyDetails.uploaded_by.first_name} ${historyDetails.uploaded_by.last_name}`
            }}
          </span>
        </div>
        <div style="margin-left: 30px" class="column">
          <span class="col-title"> Status </span>
          <span
            :class="{
              'status-success': historyDetails.status == 'complete',
              'status-error': historyDetails.status != 'complete',
            }"
          >
            {{ historyDetails.status }}
          </span>
        </div>
      </div>
      <div class="summary-details">
        <div class="summary-title">Summary</div>
        <div class="summary-row">
          <div class="summary-card">
            <span class="card-title"> Uploaded </span>
            <span style="margin-top: 6px">
              {{ historyDetails.summary.length }}
            </span>
          </div>
          <div style="margin-left: 8px" class="summary-card">
            <span class="card-title"> Verified </span>
            <span style="margin-top: 6px">
              {{
                historyDetails.summary.filter((ele) => ele.status == "verified")
                  .length
              }}
            </span>
          </div>
          <div style="margin-left: 8px" class="summary-card">
            <span class="card-title"> Brand Opted Out </span>
            <span style="margin-top: 6px">
              {{
                historyDetails.summary.filter(
                  (ele) => ele.status == "brand-opt-out"
                ).length
              }}
            </span>
          </div>
          <div style="margin-left: 8px" class="summary-card">
            <span class="card-title"> Errors </span>
            <span style="margin-top: 6px">
              {{
                historyDetails.summary.filter((ele) => ele.status == "error")
                  .length
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-btns">
        <nitrozen-button
          style="margin-right: 16px"
          v-stroke-btn
          theme="secondary"
          @click="onClose"
          >Cancel</nitrozen-button
        >
        <nitrozen-button v-flat-btn theme="secondary" @click="downloadData"
          >Download</nitrozen-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { NitrozenButton } from "@gofynd/nitrozen-vue";
import { parse, transforms } from "json2csv";
export default {
  components: {
    "nitrozen-button": NitrozenButton,
  },
  props: {
    historyDetails: {
      type: Array,
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    downloadData() {
      const { unwind } = transforms;
      let trans = [unwind({ paths: ["message"], blankOut: true })];
      const fields = [
        "name",
        "slug",
        "seo.title",
        "seo.description",
        "seo.canonical_url",
        "seo.sitemap.priority",
        "seo.sitemap.frequency",
        "status",
      ];
      const opts = { fields, includeEmptyRows: true, transforms: trans };
      const csv = parse(this.historyDetails.summary, opts);
      var link = document.createElement("a");
      let public_signed_url =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      link.setAttribute("href", public_signed_url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "history.csv");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="less" scoped>
.history-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

  .history-modal-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fefefe;
    width: 70%;
    min-height: 100vh;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    color: #41434c;
    line-height: 130%;
  }

  .upload-details {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    line-height: 140%;
    margin: 24px 0px;

    .column {
      display: flex;
      flex-direction: column;
    }

    .col-title {
      color: #9b9b9b;
      margin-bottom: 6px;
    }
    .status-text {
      text-transform: uppercase;
      color: #0a5f23;
      border: 1px solid #0a5f23;
      border-radius: 4px;
      padding: 1.5px 0.3rem;
    }
    .status-success {
      text-transform: uppercase;
      padding: 1.5px 0.3rem;
      margin-left: auto;
      color: #0a5f23;
      border: 1px solid #0a5f23;
      border-radius: 4px;
      text-transform: uppercase;
    }
    .status-error {
      text-transform: uppercase;
      padding: 1.5px 0.3rem;
      margin-left: auto;
      color: #cd0909;
      border: 1px solid #cd0909;
      border-radius: 4px;
      text-transform: uppercase;
    }
  }

  .summary-details {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;

    .summary-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 16px;
    }

    .summary-row {
      display: flex;
    }

    .summary-card {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      padding: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }

    .card-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #9b9b9b;
    }
  }

  .upload-title {
    display: flex;
    justify-content: space-between;
  }

  .modal-btns {
    margin-top: auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .close {
    width: 15px;
    height: 15px;
  }
}
</style>
