<template>
 <div style="display: flex; gap: 24px;">
  <div class="application white-box">
      <div class="section-heading">
      <h3>Features</h3>
      <!-- <p>
        Some details about new version creation
      </p> -->
    </div>
        <div class="card-main columns-4">
            <div class="card-innerbox">
                <div  @click="clicked(2)" class="card-box"> 
                  <div>
                    <img src="./../assets/Icon-history.svg" alt="icon-history">
                  </div>
                    <div class="card-content">
                        <h4>SEO Meta</h4>
                        <p>Enhance your online presence by updating SEO titles, descriptions, and alt text for your products, collections, and blogs</p>
                    </div>
                    <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
                </div>
            </div>
            <div class="card-innerbox">
                <div @click="clicked(7)" class="card-box"> 
                  <div>
                    <img src="./../assets/Icon-history.svg" alt="icon-history">
                  </div>
                    <div class="card-content">
                        <h4>On-Page SEO</h4>
                        <p>Add custom contents with trending keywords on Home Page, Collections and Product Pages</p>
                    </div>
                    <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
                </div>
            </div>
            <!-- <div class="card-innerbox">
                <div class="card-box">
                  <div>
                    <img src="./../assets/Icon-history.svg" alt="icon-history">
                  </div>
                    <div class="card-content">
                        <h4>SEO Health Score</h4>
                        <p>Stay tuned for an upcoming feature that will evaluate the health of your website's SEO."and make it disabled</p>
                    </div>
                    <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="learning-box">
        <div style="font-size: 14px; font-weight: 600">Learning Links</div>
        <div class="video-content">
          <div>
            <a href="https://platform.fynd.com/help/docs/extensions/available-extensions/seo-plus" target="_blank"><img
              src="./../assets/learning.png"
              alt="setup-video"
              style="width: 58px"
            /></a>
          </div>
          <div class="learning-details">
            <p class="heading">How to setup and use this extention ?</p>
            <p class="time">
             WATCH: 2min 30sec
            </p>
          </div>
        </div>
        <div class="hr-line">
          <hr>
        </div>
      </div>
 </div>
  </template>
  
  <script>
  import inlineSvg from "../components/inline-svg.vue";
  
  export default {
    components: {
        "inline-svg": inlineSvg
    },
    methods: {
        channelClicked(app) {
            this.$emit("pageChanged", { currentPageIndex: 1, application: app });
        },
        clicked(a,app){
          this.$emit("pageChanged", { currentPageIndex: a, application: app });
        } , 
    },
  };
  </script>
  
  <style lang="less" scoped>
  .white-box {
    height: 480px;
    border-radius: 10px;
    padding: 24px;
    background-color: #fff;
  }
  .section-heading {
    margin-bottom: 20px;
  }
  .section-heading h3 {
  margin-top: 0;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  font-size: 20px;
  color: #41434c;
}
.section-heading p {
  width: 595px;
height: 17px;
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
  margin-top: 0;
  color: #666666;
}
  .float-right {
    float: right;
  }
  
  .columns-4 {
    grid-template-columns: 33% 33% 33%;
    width: calc(100% - 36px);
  }
  
  .card-main {
    display: grid;
    grid-column-gap: 18px;
  }
  
  .card-innerbox {
    margin-bottom: 24px;
  }
  
  .card-box {
    border: 1px solid #e4e5e6;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    background-color: #fff;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
  }
  
  .card-box:hover {
    box-shadow: 5px 5px 5px 0 lightgrey;
  }
  
  .card-content {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
  }
  
  .card-content h4 {
    margin: 0;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    color: #41434c;
  }
  
  .card-content p {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #828282;
    font-family: Inter, sans-serif;
  }
  
  .card-innerbox a {
    display: block;
    width: 100%;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
  }

  .button-container {
  width: 100%;
  float: left;
  margin-top: 25px;
  padding: 14px 0;
}

.select-widget {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.select-widget {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  transform: rotate(180deg);
  padding: 6px 6px;
  display: block;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.learning-box {
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  padding: 16px 24px 0 24px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
.video-content {
  display: flex;
  gap: 4px;
}
.learning-details {
  width: auto;
}
.heading {
  font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
}
.time {
  color: #828282;
  font-family: Inter;
font-size: 10px;
font-weight: 400;
line-height: 0px;
letter-spacing: 0em;
text-align: left;
}
.hr-line > hr{
  border-color: #F2F2F2
}
</style>
  