import urlJoin from "url-join";
import root from "window-or-global";
let envVars = root.env || {};

envVars.SEOEXTENSION_MAIN_DOMAIN = `${root.location.protocol}//${root.location.hostname}`;
if (
  root &&
  root.process &&
  root.process.env &&
  root.process.NODE_ENV == "test"
) {
  envVars.SEOEXTENSION_MAIN_DOMAIN = "https://api.xyz.com";
}

const Endpoints = {
  GET_TAGS_STATUS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `api/v1.0/tagStatus`);
  },
  INJECTABLE_TAG: (application_id) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `api/v1.0/${application_id}/tag`
    );
  },
  GET_HOMEPAGE_CONFIG: (application_id) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/${application_id}/getHomePageConfig`
    );
  },
  GET_COLLECTION_CONFIG: (application_id, query) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/${application_id}/getCollectionConfig/${query}`
    );
  },
  GET_PRODUCT_CONFIG: (application_id, query) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/${application_id}/getProductConfig/${query}`
    );
  },
  SAVE_HOME_PAGE_CONFIG() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/saveHomePageConfig`
    );
  },
  SAVE_COLLECTION_PAGE_CONFIG() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/saveCollectionPageConfig`
    );
  },
  SAVE_PRODUCT_PAGE_CONFIG() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/saveProductPageConfig`
    );
  },
  GRINDOR_UPLOAD_START: (namespace) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/platform/v1.0/upload/start/${namespace}/`
    );
  },
  GRINDOR_UPLOAD_COMPLETE: (namespace) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/platform/v1.0/upload/complete/${namespace}/`
    );
  },
  GET_TEST_API() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, "/api/v1/test-api");
  },
  GET_ALL_APPLICATIONS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, "/api/v1/applications");
  },
  PUBLIC_URL: () => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/platform/v1.0/signUrls`
    );
  },
  UPLOAD_BULK_ACTION() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/upload`);
  },
  UPLOAD_TEMPLATE_BULK_ACTION() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/uploadTemplate`
    );
  },
  UPLOAD_COLLECTION_BULK_ACTION() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/uploadCollection`
    );
  },
  UPLOAD_SECTION_BULK_ACTION() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/uploadSectionPages`
    );
  },
  UPLOAD_BLOGS_BULK_ACTION() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/uploadBlogs`);
  },
  GET_ALL_DEPARTMENTS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/departments`);
  },
  GET_ALL_CATEGORIES() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/categories`);
  },
  GET_ALL_BRANDS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/brands`);
  },
  GET_FILTERED_PRODUCTS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/products`);
  },
  GET_FILTERED_ALT_PRODUCTS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/altProducts`);
  },
  GET_ALL_PRODUCTS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/allProducts`);
  },
  //   GET_ALL_PRODUCTS: application_id => {
  //     return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `api/v1.0/${application_id}/allProducts`)
  // },
  GET_ALL_COLLECTIONS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/collections`);
  },
  GET_ALL_SECTION_PAGES() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/sectionPages`);
  },
  GET_COLLECTION_BY_ID: (uid) => {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/collection/${uid}`
    );
  },
  GET_FILTERED_COLLECTIONS() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/filteredCollections`
    );
  },
  GET_ALL_BLOGS() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/blogs`);
  },
  GET_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/jobs`);
  },
  GET_ALT_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/altJobs`);
  },
  GET_COLLECTION_JOB_LIST() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/collectionJobs`
    );
  },
  GET_SECTION_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/sectionJobs`);
  },
  GET_BLOG_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/blogJobs`);
  },
  GET_SEARCHED_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/job_search`);
  },
  GET_SEARCHED_ALT_JOB_LIST() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/altJob_search`);
  },
  GET_SEARCHED_COLLECTION_JOB_LIST() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/collectionJob_search`
    );
  },
  GET_SEARCHED_SECTION_JOB_LIST() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/sectionJob_search`
    );
  },

  GET_SEARCHED_BLOG_JOB_LIST() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/blogJob_search`
    );
  },
  POST_TEST_API() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/test-api`);
  },
  UPDATE_BULK_SEO() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/update`);
  },
  UPDATE_BULK_TEMPLATE() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/updateTemplate`
    );
  },
  UPDATE_A_COLLECTION() {
    return urlJoin(
      envVars.SEOEXTENSION_MAIN_DOMAIN,
      `/api/v1.0/updateCollection`
    );
  },
  UPDATE_A_SECTION() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/updateSection`);
  },
  UPDATE_A_BLOG() {
    return urlJoin(envVars.SEOEXTENSION_MAIN_DOMAIN, `/api/v1.0/updateBlog`);
  },
};

export default Endpoints;
