<template>
  <div style="display: flex; gap: 24px">
    <div class="application white-box">
      <div class="title-element">
        <div class="title">
          <p class="page-title">On Page SEO</p>
          <p class="below-title">
            Add custom contents with trending keywords on Home Page, Collections
            and Product Pages
          </p>
        </div>
        <div
          class="toggle-container"
          @mouseover="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <div class="info-container">
            <img
              v-show="isHovered"
              src="./../../assets/hover_box.svg"
              alt="hover-box"
              class="hover-box"
            />
            <span v-show="isHovered" class="info"
              >Master Control to activate or deactivate on page SEO content from
              one place</span
            >
          </div>
          <div class="global-script-toggle">
            <p>{{ toggleStatus }}</p>
            <NitrozenToggleBtn
              id="n-toggle-keya1k9ub"
              showIcon
              value
              v-model="toggleValue"
              helperText="helper.."
              @input="masterToggle()"
            ></NitrozenToggleBtn>
          </div>
        </div>
      </div>
      <div v-if="getTagRes == true && !pageLoading" class="card-main columns-4">
        <div class="card-innerbox">
          <div @click="clicked(8)" class="card-box">
            <div>
              <img src="./../../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Home Page</h4>
              <p>Configure content to be shown on Homepage of your website</p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(10)" class="card-box">
            <div>
              <img src="./../../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Collections</h4>
              <p>
                Configure content to be shown on Collection pages of your
                website
              </p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-innerbox">
          <div @click="clicked(12)" class="card-box">
            <div>
              <img src="./../../assets/Icon-history.svg" alt="icon-history" />
            </div>
            <div class="card-content">
              <h4>Products</h4>
              <p>
                Configure content to be shown on Product pages of your website
              </p>
            </div>
            <div class="button-container">
              <div class="select-widget float-right">
                <inline-svg :src="'long-left-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <loader :helperText="helperText" v-if="getTagRes == false && pageLoading == true"></loader>
      </div>
    </div>
      <div v-if="getTagRes == true && pageLoading == false" class="learning-box">
        <div style="font-size: 14px; font-weight: 600">Learning Links</div>
        <div class="video-content">
          <div>
            <a href="https://platform.fynd.com/help/docs/extensions/available-extensions/seo-plus" target="_blank"><img
              
              src="./../../assets/learning.png"
              alt="setup-video"
              style="width: 58px"
            /></a>
          </div>
          <div class="learning-details">
            <p class="heading">How to setup and use this extention ?</p>
            <p class="time">
             WATCH: 2min 30sec
            </p>
          </div>
        </div>
        <div class="hr-line">
          <hr>
        </div>
      </div>
  </div>
</template>

<script>
import MainService from "../../services/main-service";
import inlineSvg from "../../components/inline-svg.vue";
import { NitrozenToggleBtn } from "@gofynd/nitrozen-vue";
import Loader from "../../components/loader.vue";

export default {
  components: {
    Loader,
    NitrozenToggleBtn,
    inlineSvg,
  },
  props: {
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      pageLoading: false,
      toggleValue: false,
      application_id: "",
      tag: "",
      getTagRes: false,
      toggleStatus: "Inactive",
      isHovered: false,
      showVideoPlayer: false,
    };
  },
  mounted() {
    this.getTags();
  },
  methods: {
    clicked(a) {
      this.$emit("pageChanged", {
        currentPageIndex: a,
        scriptStatus: this.toggleValue,
      });
    },
    // playVideo() {
    //   this.showVideoPlayer = !this.showVideoPlayer;
    // },
    async getTags() {
      this.pageLoading = true;
      return await MainService.getInjectableTag(this.selectedChannel?.id)
        .then((res) => {
          if (res.data?.data?.tags.length > 0) {
            this.toggleValue = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.pageLoading = false;
          this.getTagRes = true;
        });
    },
    async masterToggle() {
      if (this.toggleValue === true) {
        await MainService.addInjectableTag(this.selectedChannel?.id);
        
      } else {
        await MainService.removeInjectableTag(this.selectedChannel?.id);
      }
    },
  },
  watch: {
    toggleValue(value) {
      if (value == true) {
        this.toggleStatus = "Active";
      } else {
        this.toggleStatus = "Inactive";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title-element {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  float: left;
  flex-direction: column;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.below-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #666666;
}
.toggle-container {
  display: flex;
  flex-direction: column;
}
.info-container {
  position: relative;
}
.hover-box {
  position: absolute;
  bottom: -16px;
  left: 18px;
}
.info {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  position: absolute;
  bottom: 8px;
  width: 275px;
  left: 45px;
  color: white;
}
.global-script-toggle {
  display: flex;
  align-items: center;
}

.global-script-toggle > p {
  padding-right: 10px;
}

.page-title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 0%;
  color: #41434c;
}

.white-box {
  height: 480px;
  border-radius: 10px;
  padding: 24px;
  background-color: #fff;
}

.columns-4 {
  grid-template-columns: 33.33% 33.33% 33.33%;
  width: calc(100% - 36px);
}

.card-main {
  display: grid;
  grid-column-gap: 18px;
}

.card-box {
  width: 100%;
  float: left;
  padding: 14px 0;
  border: 1px solid #e4e5e6;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.card-box:hover {
  box-shadow: 5px 5px 5px 0 lightgrey;
}

.select-widget {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.select-widget {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  transform: rotate(180deg);
  padding: 6px 6px;
  display: block;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
}

.card-content {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.card-content h4 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #41434c;
}

.card-content p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #828282;
  font-family: Inter, sans-serif;
}

.card-innerbox a {
  display: block;
  width: 100%;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}

.button-container {
  width: 100%;
  float: left;
  margin-top: 25px;
  padding: 14px 0;
}
.learning-box {
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  padding: 16px 24px 0 24px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
.video-content {
  display: flex;
  gap: 4px;
}
.learning-details {
  width: auto;
}
.heading {
  font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
}
.time {
  color: #828282;
  font-family: Inter;
font-size: 10px;
font-weight: 400;
line-height: 0px;
letter-spacing: 0em;
text-align: left;
}
.hr-line > hr{
  border-color: #F2F2F2
}
</style>
