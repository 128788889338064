<template>
  <div>
    <div class="status-header">
      <div>
        <div class="status-title">Upload Status</div>
        <div class="status-details">
          <span class="field">Total Row Entries:</span>
          <span class="field-data">{{ details.length }}</span>
          <span style="margin-left: 5px" class="field">Verified:</span>
          <span class="field-data">
            {{ details.filter((ele) => ele.status == "verified").length }}
          </span>
          <span v-if="details.length" style="margin-left: 5px" class="field"
            >Brand Opted Out:</span
          >
          <span v-if="details.length" class="field-data">
            {{ details.filter((ele) => ele.status == "brand-opt-out").length }}
          </span>
          <span style="margin-left: 5px" class="field">Errors:</span>
          <span class="field-data">
            {{ details.filter((ele) => ele.status == "error").length }}
          </span>
        </div>
      </div>
      <div v-if="details.length > 0" class="log-donwload">
        <nitrozen-button
          style="margin-right: 18px"
          theme="secondary"
          @click="downloadLogs"
          >Validation Log</nitrozen-button
        >
        <nitrozen-button v-flat-btn theme="secondary" @click="onProceedClick"
          >Proceed</nitrozen-button
        >
      </div>
    </div>
    <div v-if="details.length > 0" class="validation-filter">
      <div class="validation-search">
        <nitrozen-input
          type="search"
          placeholder="Search By Name"
          :showSearchIcon="true"
          @input="searchInValidation"
          v-model="searchProduct"
        ></nitrozen-input>
      </div>
      <div class="validation-status">
        <nitrozen-dropdown
          label="Status"
          class="validation-status-dropdown"
          placeholder="Status"
          :items="validation_status"
          v-model="selectedStatus"
          @change="onStatusChange"
        />
      </div>
    </div>
    <div v-for="(detail, index) in paginated_details" :key="index">
      <div class="validation-card">
        <div class="validation-details">
          <div class="card-title">{{ detail.name }}</div>
          <div class="details-row">
            <div style="padding-left: 0px" class="validation-field">
              <span class="field-title">Section Name</span>
              <span>{{ shortString(detail?.name) }}</span>
            </div>
            <div class="validation-field">
              <span class="field-title">SEO Title</span>
              <span>{{ shortString(detail?.seo?.title) }}</span>
            </div>
            <div class="validation-field">
              <span class="field-title">SEO Description</span>
              <span>{{ shortString(detail?.seo?.description) }}</span>
            </div>
            <div class="validation-field">
              <span class="field-title">Canonical Url</span>
              <span>{{ shortString(detail?.seo?.canonical_url) }}</span>
            </div>
            <div class="validation-field">
              <span class="field-title">Priority</span>
              <span>{{ detail?.seo?.sitemap?.priority }}</span>
            </div>
            <div class="validation-field">
              <span class="field-title">Frequency</span>
              <span>{{ detail?.seo?.sitemap?.frequency }}</span>
            </div>
          </div>
        </div>
        <div class="validation-button">
          <div
            :class="{
              'validation-status-success': detail.status == 'verified',
              'validation-status-error': detail.status != 'verified',
            }"
          >
            {{ detail.status }}
          </div>
        </div>
      </div>
    </div>
    <nitrozen-pagination
      style="margin-top: 10px"
      v-if="paginated_details.length > 0"
      name="Result"
      v-model="pagination"
      @change="setPagination"
      :pageSizeOptions="[5, 10, 20, 50]"
    ></nitrozen-pagination>
  </div>
</template>

<script>
import {
  NitrozenButton,
  NitrozenPagination,
  NitrozenInput,
  NitrozenDropdown,
} from "@gofynd/nitrozen-vue";
import { parse, transforms } from "json2csv";
import MainService from "../../services/main-service";
const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};
export default {
  mounted() {},
  components: {
    "nitrozen-button": NitrozenButton,
    "nitrozen-dropdown": NitrozenDropdown,
    "nitrozen-input": NitrozenInput,
    NitrozenPagination,
  },
  props: {
    details: {
      type: Array,
    },
    selectedChannel: {
      type: Object,
    },
    validated_id: {
      type: String,
    },
  },
  data() {
    return {
      pagination: { ...PAGINATION },
      paginated_details: [],
      validationFilterData: [],
      validation_status: [
        { text: "Verified", value: "verified" },
        { text: "Error", value: "error" },
        { text: "Brand-Opt-Out", value: "brand-opt-out" },
      ],
      searchProduct: null,
      selectedStatus: null,
    };
  },
  watch: {
    details() {
      this.searchInValidation("");
    },
  },
  methods: {
    shortString(str = "") {
      return ` ${str && str.length > 20 ? str?.slice(0, 20) + "..." : str}`;
    },
    async downloadLogs() {
      const { unwind } = transforms;
      let trans = [unwind({ paths: ["message"], blankOut: true })];
      const fields = [
        "name",
        "slug",
        "seo.title",
        "seo.description",
        "seo.sitemap.priority",
        "seo.sitemap.frequency",
        "status",
        "message",
      ];
      const opts = { fields, includeEmptyRows: true, transforms: trans };
      const csv = parse(this.details, opts);
      var link = document.createElement("a");
      let public_signed_url =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      link.setAttribute("href", public_signed_url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "validation-logs.csv");
      document.body.appendChild(link);
      link.click();
    },
    async onProceedClick() {
      return await MainService.updateCollection({
        validated_id: this.validated_id,
        app: this.selectedChannel,
      })
        .then(({ data }) => {
          if (data.success) {
            this.$snackbar.global.showSuccess(
              "collection seo values will be updated shortly!!"
            );
          } else {
            this.$snackbar.global.showError("Error While Updating collection!");
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(
            `${err} While Updating collection seo values!`
          );
        })
        .finally(() => {
          this.$emit("onClose");
        });
    },
    setPagination(updatedPagination) {
      this.pagination = Object.assign({}, this.pagination, updatedPagination);
      let currIndex = this.pagination.limit * (this.pagination.current - 1);
      this.paginated_details = this.validationFilterData.slice(
        currIndex,
        currIndex + this.pagination.limit
      );
    },
    searchInValidation(searchQuery) {
      this.validationFilterData = this.details.filter((ele) =>
        ele?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      this.selectedStatus = null;
      this.setPagination({
        limit: 10,
        current: 1,
        total: this.validationFilterData.length,
      });
    },
    onStatusChange(value) {
      this.searchProduct = null;
      this.validationFilterData = this.details.filter(
        (ele) => ele?.status == value
      );
      this.setPagination({
        limit: 10,
        current: 1,
        total: this.validationFilterData.length,
      });
    },
  },
  beforeUpdate() {
    if (
      this.searchProduct == null &&
      this.selectedStatus == null &&
      this.validationFilterData.length == 0 &&
      this.details.length != 0
    ) {
      this.validationFilterData = this.details;
    }
    if (
      this.validationFilterData.length > 0 &&
      this.paginated_details.length == 0
    ) {
      this.paginated_details = this.validationFilterData.slice(
        0,
        this.pagination.limit
      );
      this.pagination.total = this.validationFilterData.length;
    } else if (
      this.validationFilterData.length == 0 &&
      this.paginated_details.length != 0
    ) {
      this.paginated_details = [];
    }
  },
};
</script>

<style lang="less">
.status-header {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .log-donwload {
    margin-left: auto;
  }

  .status-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #41434c;
  }

  .status-details {
    margin-top: 5px;
  }

  .field {
    font-size: 14px;
    color: #9b9b9b;
  }

  .field-data {
    font-size: 14px;
    color: #41434c;
  }
}

.validation-card {
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
    color: #41434c;
  }
  .error-msg {
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
    color: #cd0909;
  }
  .validation-details {
    display: flex;
    flex-direction: column;
    flex: 6;
  }
  .validation-field {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  .field-title {
    font-size: 12px;
    color: #9b9b9b;
    margin-bottom: 4px;
  }
  .slug {
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .validation-button {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    padding-top: 14px;
  }
  .validation-status-success {
    padding: 1px 19px;
    margin-left: auto;
    color: #0a5f23;
    border: 1px solid #0a5f23;
    border-radius: 4px;
    height: 21px;
  }
  .validation-status-error {
    padding: 1px 19px;
    margin-left: auto;
    color: #cd0909;
    border: 1px solid #cd0909;
    border-radius: 4px;
    height: 21px;
  }
  .details-row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
}

.validation-filter {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: #f5f5f5;
  margin-top: 16px;

  .validation-search {
    width: 68%;
  }
  .validation-status {
    width: 29%;
  }
}

.validation-status-dropdown {
  label {
    display: none !important;
  }
}

.nitrozen-dropdown-label {
  display: none !important;
}
</style>
