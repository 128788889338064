<template>
  <div class="bulk-modal">
    <div class="bulk-modal-container">
      <div class="modal-title">
        Bulk Action For Sections
        <div class="close-icon" @click="onClose">
          <img src="../../assets/close-icon.png" class="close" />
        </div>
      </div>
      <div>
        <div class="file-container">
          <div class="download-template">
            <div class="select-type">
              <div class="title">1. Download</div>
              <div class="download-type" @click="getElement">
                <nitrozen-dropdown
                  class="download-box"
                  :items="file_type"
                  v-model="selected_file_type"
                  :enable_select_all="true"
                  @change="onDownloadFileTypeChange"
                />
              </div>
            </div>
            <button class="download-btn" @click="() => downloadExcel()">
              <div class="wrapper" v-show="!isButtonDisable">Download Data</div>
              <img class="loader" v-show="isButtonDisable" :src="getLoader" />
            </button>
          </div>
          <div class="upload-file">
            <div class="upload-title">
              <div class="title">2. Upload</div>
            </div>
            <FileUploader
              :class="{ highlight, disabled: false }"
              ref="fileuploader"
              :total-size="totalSize"
              :acceptedFileTypesText="acceptedFileTypesText"
              :isFileCleared="isFileCleared"
              :note="'Note: Upload Sections (max 300 at a time)'"
              :acceptedMIMETypes="acceptedMIMETypes"
              :error="csvModalError"
              :title="'Upload the File'"
              @input="onFileSelect"
              @onDrop="onDropFile"
              @clearInputFile="clearInputFile"
              @clearData="clearData"
              :fileUploadPercentage="fileUploadPercentage"
              :file-uploaded="fileUploadPercentage === 100"
            ></FileUploader>
          </div>
        </div>
        <div class="upload-status">
          <ValidationDetails
            @onClose="onClose"
            :details="verified_data"
            :validated_id="validated_id"
            :selectedChannel="selectedChannel"
          ></ValidationDetails>
        </div>
        <div
          v-if="verified_data.length <= 0 && !isPageLoading"
          class="empty-state"
        >
          <img src="../../assets/empty.png" width="100%" />
        </div>
        <div>
          <loader v-if="isPageLoading"></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NitrozenDropdown, strokeBtn, flatBtn } from "@gofynd/nitrozen-vue";
import ValidationDetails from "./validation-detail.vue";
import FileUploader from "./../file-uploader.vue";
import MainService from "../../services/main-service";
import { bytesToSize } from "../../helper/sizeConverter";
import get from "lodash/get";
import { parse } from "json2csv";
import papa from "papaparse";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Loader from "../../components/loader.vue";
import {
  ACCEPTED_MIME_TYPE,
  SUPPORTED_INPUT_FILE_TYPES,
} from "../../helper/utils";

const PUBLIC_SIGNED_URL_EXPIRY = 60 * 60; // in minutes
const PAGINATION = {
  current: 1,
  limit: 50,
  has_next: true,
};

const WORKSHEET_COLUMNS = [
  {
    header: "Section Page Id",
    key: "page_id",
    width: 15,
  },
  {
    header: "Type",
    key: "type",
    width: 15,
  },
  {
    header: "Name",
    key: "name",
    width: 15,
  },
  {
    header: "Slug",
    key: "slug",
    width: 15,
  },
  {
    header: "SEO Title",
    key: "seoTitle",
    width: 25,
  },
  {
    header: "SEO Description",
    key: "seoDescription",
    width: 60,
  },
  {
    header: "Canonical Url",
    key: "canonicalUrl",
    width: 60,
  },
  {
    header: "Priority",
    key: "sitemapPriority",
    width: 60,
  },
  {
    header: "Frequency",
    key: "sitemapFrequency",
    width: 60,
  },
];

const MANDATORY_COLUMNS = [
  {
    column: "A",
    header: "Section Page Id",
    col_index: 0,
  },
  {
    column: "B",
    header: "Type",
    col_index: 1,
  },
  {
    column: "C",
    header: "Name",
    col_index: 2,
  },
  {
    column: "D",
    header: "Slug",
    col_index: 3,
  },
];

export default {
  components: {
    "nitrozen-dropdown": NitrozenDropdown,
    FileUploader,
    ValidationDetails,
    Loader,
  },
  directives: {
    strokeBtn,
    flatBtn,
  },
  props: {
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      acceptedFileTypesText: "Accepted file types: .xlsx and .xls",
      isFileCleared: false,
      enableBtn: false,
      totalSize: "",
      csvModalError: "",
      highlight: true,
      isPageLoading: false,
      sample_section_data: [],
      verified_data: [],
      validated_id: null,
      fileUploadStatus: {
        error: false,
        message: "",
      },
      file_type: [
        { text: "Excel (.xlsx)", value: "excel" },
        // { text: "Csv (.csv)", value: "csv" },
      ],
      selected_file_type: "excel",
      fileUploadPercentage: 0,
      isValidFile: true,
      isButtonDisable: false,
      searchQuery: "",
    };
  },

  computed: {
    getLoader() {
      return require("../../assets/loader.gif");
    },
    acceptedMIMETypes() {
      return ACCEPTED_MIME_TYPE;
    },
  },
  methods: {
    async onFileSelect(e) {
      this.isFileCleared = false;
      this.verified_data = [];
      Promise.resolve()
        .then(() => {
          this.fileUploadPercentage = 0;
          if (e.file) {
            this.file = e.file;
            this.totalSize = bytesToSize(e.file.size);
            const fileExtension = e.file.name.split(".").pop();
            if (!SUPPORTED_INPUT_FILE_TYPES.includes(fileExtension)) {
              this.isFileCleared = false;
              this.$snackbar.global.showError("Unsupported file format");
              this.clearInputFile();
              return;
            }
            this.isValidFile = true;
            this.validateExcel(e);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    onDropFile(e) {
      this.highlight = false;
      const files = e.dataTransfer.files;
      if (!files || !files.length) {
        this.$snackbar.global.showError("No file selected");
        return;
      }
      this.file = files[0];
      e.file = files[0];
      FileUploader.data().file = e.file;
      this.onFileSelect(e);
    },
    uploadFile(e) {
      this.isPageLoading = true;
      this.fileUploadedSuccessfully = false;
      this.enableBtn = false;
      if (e && e.file) {
        return this.$refs.fileuploader
          .uploadToGrindor(
            "platform-extensions",
            this.$route.params.company_id,
            this.fileUploadProgress
          )
          .then(({ data }) => {
            if (data.cdn && data.cdn.url) {
              return this.getPublicUrl([data.cdn.url], PUBLIC_SIGNED_URL_EXPIRY)
                .then((publicUrlResponse) => {
                  let public_signed_url = get(publicUrlResponse, "urls.0.url");
                  return this.uploadBulkAction({
                    file_url: public_signed_url,
                    original_file_url: data.cdn.url,
                  });
                })
                .then((res) => {
                  if (res) {
                    this.fileUploadPercentage = 100;
                    this.$snackbar.global.showSuccess(
                      "File Uploaded successfully"
                    );
                  }
                })
                .catch((err) => {
                  this.fileUploadPercentage = 0;
                  this.clearInputFile();
                  return err;
                });
            }
          })
          .catch((err) => {
            this.fileUploadPercentage = 0;
            this.$snackbar.global.showError(err.message);
            this.clearInputFile();
            return err;
          });
      }
    },
    async validateExcel(e) {
      this.isValidFile = true;
      const wb = new ExcelJS.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          let worksheet = workbook.worksheets[0];
          let headerRow = worksheet.getRow(1).values;
          console.log("worksheet", worksheet);

          WORKSHEET_COLUMNS.every((col, index) => {
            if (col.header != headerRow[index + 1]) {
              this.isValidFile = false;
              this.$snackbar.global.showError(
                `Header not included ${col.header}`
              );
              return false;
            }
            return true;
          });

          MANDATORY_COLUMNS.every((col) => {
            worksheet.getColumn(col.column).eachCell((cell, rowNumber) => {
              if (
                rowNumber != 0 &&
                (cell.value == null || cell.value.toString() == "")
              ) {
                console.log(
                  "col,col.column,cell.value,cell.value,col.header",
                  col,
                  col.column,
                  cell.value,
                  cell.value,
                  col.header
                );
                this.isValidFile = false;
                this.$snackbar.global.showError(
                  `${col.header} field shoudnt be empty`
                );
                return false;
              }
            });
            return true;
          });
          this.onValidatoinComplete(e);
        });
      };
    },
    onValidatoinComplete(e) {
      if (this.isValidFile) {
        this.uploadFile(e);
      } else {
        this.$snackbar.global.showError("Invalid Columns In File");
        this.clearInputFile();
      }
    },
    fileUploadProgress(progressEvent) {
      this.fileUploadPercentage =
        (100 * progressEvent.loaded) / progressEvent.total;
    },
    async uploadBulkAction(data) {
      this.verified_data = [];
      return await MainService.uploadBulkSection({
        ...data,
        app: this.selectedChannel,
      })
        .then(async (res) => {
          if (res.data.success) {
            this.validated_id = res.data.validated_id;
            this.verified_data = [...res.data.data];
            return res;
          } else {
            this.$snackbar.global.showError(res.data.message); // error logs
            this.clearInputFile();
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching logs`);
        })
        .finally(() => {
          this.isPageLoading = false;
        });
    },
    async getPublicUrl(urls, expiry) {
      return await MainService.getPublicUrl({
        expiry,
        urls,
      }).then((res) => {
        return res.data;
      });
    },
    clearInputFile() {
      this.verified_data = [];
      this.isFileCleared = true;
      this.enableBtn = false;
      this.$refs.fileuploader.clearInputFile();
      this.file = null;
    },
    clearData() {
      this.verified_data = [];
    },
    async getAllSectionPagesData() {
      this.isButtonDisable = true;
      return await MainService.getAllSectionPagesData({
        app: this.selectedChannel,
      })
        .then(({ data }) => {
          this.sample_section_data = [];
          data.data.pages.map((page) => {
            this.sample_section_data.push({
              page_id: page._id,
              type: page.type,
              name: page.text,
              slug: page.value,
              seoTitle: page.seo.title,
              seoDescription: page.seo.description,
              canonicalUrl: page?.seo?.canonical_url,
              sitemapPriority: page?.seo?.sitemap?.priority,
              sitemapFrequency: page?.seo?.sitemap?.frequency,
            });
          });
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching collections`);
        })
        .finally(() => {
          this.isButtonDisable = false;
        });
    },
    getElement() {
      const ele = document.getElementsByClassName("nitrozen-select");
      ele[0].style.border = "none";
      ele[0].style.width = "7.rem";
      const inp = document.getElementsByClassName("nitrozen-select__trigger");
      inp[0].style.color = "#2E31BE";
      inp[0].style.fontWeight = 700;
    },
    async downloadExcel() {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();
      ws.columns = WORKSHEET_COLUMNS;

      MANDATORY_COLUMNS.forEach((col) => {
        ws.getCell(`${col.column}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "90EE90" },
        };
        ws.getCell(`${col.column}1`).font = {
          bold: true,
          size: 14,
        };
        ws.getCell(`${col.column}1`).border = {
          top: { style: "hair" },
          left: { style: "hair" },
          bottom: { style: "hair" },
          right: { style: "hair" },
        };
      });
      const xlLength = this.sample_section_data.length;
      this.sample_section_data.forEach((item, index) => {
        ws.getRow(index + 2).values = {
          page_id: item.page_id,
          type: item.type,
          name: item.name,
          slug: item.slug,
          seoTitle: item.seoTitle,
          seoDescription: item.seoDescription,
          canonicalUrl: item?.canonicalUrl,
          sitemapPriority: item?.sitemapPriority,
          sitemapFrequency: item?.sitemapFrequency,
        };
      });

      const buf = await wb.xlsx.writeBuffer();

      saveAs(new Blob([buf]), "Sections.xlsx");
      if (buf && xlLength > 0) {
        this.$snackbar.global.showSuccess(
          `${xlLength} Sections Downloaded successfully`
        );
      } else {
        this.$snackbar.global.showError(`Sections not available!`);
      }
    },
    onClose() {
      this.$emit("onClose");
    },
  },
  mounted() {
    this.getAllSectionPagesData();
  },
  destroyed() {
    this.getElement();
  },
};
</script>

<style lang="less" scoped>
.bulk-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%; /* Full width *//* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  margin-top: -1rem;

  .bulk-modal-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fefefe;
    width: 70%;
    min-height: 100vh;
    overflow: auto;
    bottom: 0;
    padding: 24px;
    z-index: 20;
  }

  .modal-title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
  }

  .upload-title {
    display: flex;
    justify-content: space-between;
  }


  .close {
    width: 15px;
    height: 15px;
  }

  .filter-text {
    font-weight: 700;
    font-size: 12px;
    color: #2e31be;
    margin-top: 16px;
    cursor: pointer;

    img {
      padding-right: 0.1rem;
    }
  }

  .download-btn {
    margin-top: 25px;
    width: 100%;
    border: 1px solid #2e31be;
    border-radius: 4px;
    background: white;
    height: 40px;
    padding: 9.5px 16px;
    color: #2e31be;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    .wrapper {
    width: 100%;
    > * {
      display: block;
    }
  }
  .loader {
    height: inherit;
    margin: 0 auto;
    padding: 0;
  }
  }
  .download-btn:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }

  .ntz-down-btn{
    margin-top: 10px;
    width: 100%;
  }

  .action-btn > * {
    margin: 0.8rem;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
  }

  .dropdown {
    margin-top: 16px;
  }


  .file-container {
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    .download-template {
      border: 1px solid #e0e0e0;
      width: 50%;
      padding: 24px;
      margin-right: 12px;
      border-radius: 4px;
    }
    .select-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .upload-file {
      border: 1px solid #e0e0e0;
      width: 50%;
      padding: 24px;
      margin-left: 12px;
      border-radius: 4px;
    }
  }

  .close-icon {
    cursor: pointer;
  }
  .empty-state {
    text-align: center;
  }
}

.box{
  position: relative;
  &::after{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    z-index: 7;
  }
}
</style>
