var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","gap":"24px"}},[_c('div',{staticClass:"application white-box"},[_vm._m(0),_c('div',{staticClass:"card-main columns-4"},[_c('div',{staticClass:"card-innerbox"},[_c('div',{staticClass:"card-box",on:{"click":function($event){return _vm.clicked(2)}}},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"select-widget float-right"},[_c('inline-svg',{attrs:{"src":'long-left-arrow'}})],1)])])]),_c('div',{staticClass:"card-innerbox"},[_c('div',{staticClass:"card-box",on:{"click":function($event){return _vm.clicked(7)}}},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"select-widget float-right"},[_c('inline-svg',{attrs:{"src":'long-left-arrow'}})],1)])])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-heading"},[_c('h3',[_vm._v("Features")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("./../assets/Icon-history.svg"),"alt":"icon-history"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('h4',[_vm._v("SEO Meta")]),_c('p',[_vm._v("Enhance your online presence by updating SEO titles, descriptions, and alt text for your products, collections, and blogs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("./../assets/Icon-history.svg"),"alt":"icon-history"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('h4',[_vm._v("On-Page SEO")]),_c('p',[_vm._v("Add custom contents with trending keywords on Home Page, Collections and Product Pages")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learning-box"},[_c('div',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v("Learning Links")]),_c('div',{staticClass:"video-content"},[_c('div',[_c('a',{attrs:{"href":"https://platform.fynd.com/help/docs/extensions/available-extensions/seo-plus","target":"_blank"}},[_c('img',{staticStyle:{"width":"58px"},attrs:{"src":require("./../assets/learning.png"),"alt":"setup-video"}})])]),_c('div',{staticClass:"learning-details"},[_c('p',{staticClass:"heading"},[_vm._v("How to setup and use this extention ?")]),_c('p',{staticClass:"time"},[_vm._v(" WATCH: 2min 30sec ")])])]),_c('div',{staticClass:"hr-line"},[_c('hr')])])
}]

export { render, staticRenderFns }