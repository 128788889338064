<template>
   <div class="color-picker">
    <div class="text-container">
      <div class="hashbox">
        <img src="./../assets/hash.svg" alt="hash-sign">
      </div>
     <div class="title">
      <span>
        {{ label }}
      </span>
     </div>
    </div>
 <div class="color-input">
  <input
        type="color"
        class="color-bar"
        :value="colorValue"
        v-model="colorValue"
        @input="onChange($event)"
      />
 </div>
   </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.colorValue = this.color;
    },
    watch:{
      color(value){
        this.colorValue = value;
      this.label = value.slice(1);
      }
    },
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    },
    data() {
      return {
        colorValue: "",
        label:"000000"
      };
    },
    methods: {
      onChange(e) {
        this.colorValue = e.target.value;
        this.label = e.target.value.slice(1);
        this.$emit("color-change", this.colorValue);
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
    .color-picker {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .text-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    border: 1px solid #e0e0e0;
  border-radius: 4px;
  gap: 5px;
  }
  .title {
    font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 24px;
color: #666666;
  }
  .color-input {
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .color-bar {
      width: 50px;
      height: 40px;
      border: none;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    }
    .hashbox {
      display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px 0 0 4px;
  height: 100%;
  width: 43px;
  background-color: #f5f5f5;
}
  </style>
  