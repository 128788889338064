<template>
  <div class="content">
    <div class="page-title">
      Upload History
      <div>
        <nitrozen-button
          v-if="history_data.length != 0"
          class="refresh-btn"
          theme="secondary"
          @click="loadHistory"
        >
          <img src="../../assets/refresh.svg" />
          Refresh
        </nitrozen-button>
        <nitrozen-button v-flat-btn theme="secondary" @click="onBulkAction"
          >Bulk Action</nitrozen-button
        >
      </div>
    </div>

    <div v-if="openBulkAction">
      <bulk-action-modal
        :selectedChannel="selectedChannel"
        @onClose="closeModal"
      ></bulk-action-modal>
    </div>

    <div v-if="historyDetailsModal">
      <HistoryStatus
        :historyDetails="selectedHistory"
        @onClose="closeModal"
      ></HistoryStatus
      >history-status
    </div>

    <div class="history-filter">
      <div class="history-search">
        <nitrozen-input
          type="search"
          placeholder="Search By Job ID"
          :showSearchIcon="true"
          class="history-list"
          @input="searchHistory"
        ></nitrozen-input>
      </div>
      <div class="history-date-filter">
        <date-picker
          class="date-picker filter-input-sm history-list"
          picker_type="date"
          date_format="YYYY-MM-DD"
          v-model="historyDateRange"
          :clearable="true"
          :range="true"
          :not_before="notBefore"
          :not_after="new Date().toISOString()"
          :useNitrozenTheme="true"
          @input="dateRangeChange"
        />
      </div>
    </div>
    <div class="seo-history-container">
      <div
        :key="job"
        class="seo-history-box"
        v-for="job of history_data"
        @click="onHistoryClick(job)"
      >
        <div class="logo">
          <img src="../../assets/CSV.svg" />
        </div>
        <div class="channel-details">
          <div class="line-1">
            <span>
              {{ `Job ID : ${job._id}` }}
            </span>
            <img
              style="margin-left: 16px"
              src="../../assets/cloud.svg"
              class="cloud"
              @click="downloadData(job)"
            />
          </div>
          <div class="line-2">
            {{ "Uploaded by " }}
            {{ `${job.uploaded_by.first_name} ${job.uploaded_by.last_name}` }}
            {{ " | " }} {{ getFormattedDate(job.created_at) }}
          </div>
          <div class="seo-list-btn-cont"></div>
        </div>
        <div class="history-status">
          <div
            :class="{
              'status-success': job.status == 'complete',
              'status-error': job.status != 'complete',
            }"
          >
            {{ job.status }}
          </div>
        </div>
      </div>
      <div v-if="history_data.length == 0 && !pageLoading">
        <div>
          <img src="../../assets/empty_state.png" alt="Page Not Found" />
        </div>
        <div class="no-history">No History to Show.</div>
      </div>
    </div>

    <nitrozen-pagination
      v-if="history_data.length != 0 && !pageLoading"
      name="Uploads"
      v-model="pagination"
      @change="setPagination"
      :pageSizeOptions="[5, 10, 20, 50]"
    ></nitrozen-pagination>

    <loader v-if="pageLoading"></loader>
  </div>
</template>

<script>
import {
  NitrozenInput,
  NitrozenButton,
  NitrozenPagination,
} from "@gofynd/nitrozen-vue";
import BulkActionModal from "./bulk-action.vue";
import HistoryStatus from "./history-status.vue";
import MainService from "../../services/main-service";
import { parse } from "json2csv";
import moment from "moment";
import DatePicker from "../../components/date-picker.vue";
import Loader from "../../components/loader.vue";

const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};
const MONTHS = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  components: {
    "nitrozen-input": NitrozenInput,
    "nitrozen-button": NitrozenButton,
    BulkActionModal,
    HistoryStatus,
    NitrozenPagination,
    DatePicker,
    Loader,
  },
  props: {
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      openBulkAction: false,
      historyDetailsModal: false,
      history_data: [],
      pageLoading: false,
      isError: false,
      companyId: this.$route.params.company_id,
      data: [],
      pagination: { ...PAGINATION },
      scrollIntoView: false,
      selectedHistory: null,
      notBefore: moment("01012020", "DDMMYYYY").toISOString(),
      historyDateRange: ["", ""],
      current_user: "",
    };
  },
  mounted() {
    this.getCurrentUser();
    this.loadHistory();
  },
  methods: {
    onBulkAction() {
      this.openBulkAction = true;
    },
    async getCurrentUser() {
      try {
        let data = await MainService.getCurrentUser();
        this.current_user = data.data.user;
      } catch (error) {
        return error;
      }
    },
    loadHistory() {
      this.history_data = [];
      this.pageLoading = true;
      this.$nextTick(() => {
        if (this.scrollIntoView) {
          this.scrollListIntoVIew();
        }
        this.scrollIntoView = true;
      });
      let params = {
        pagination: {
          page_size: this.pagination.limit || 10,
          page_no: this.pagination.current || 1,
        },
        date_range: this.historyDateRange,
        app: this.selectedChannel,
      };
      MainService.getCollectionJobList(params)
        .then(({ data }) => {
          this.history_data = data.job_list || [];
          this.pagination.total = data.total;
        })
        .catch((err) => {
          this.isError = true;
          return err;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    scrollListIntoVIew() {
      const element = this.$refs?.historyList;
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo(0, middle);
    },
    getFormattedDate(date) {
      let d = new Date(date);
      let newDate = `${
        MONTHS[d.getMonth()]
      } ${d.getDate()}, ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${
        d.getHours() >= 12 ? "PM" : "AM"
      }`;
      return newDate;
    },
    onHistoryClick(job) {
      this.historyDetailsModal = true;
      this.selectedHistory = job;
    },
    closeModal() {
      this.openBulkAction = false;
      this.historyDetailsModal = false;
      this.loadHistory();
    },

    setPagination(updatedPagination) {
      this.pagination = Object.assign({}, this.pagination, updatedPagination);
      this.loadHistory();
    },
    downloadData(job) {
      const fields = [
        "name",
        "description",
        "slug",
        "seo.title",
        "seo.description",
        "seo.sitemap.priority",
        "seo.sitemap.frequency",
        "status",
      ];
      const opts = { fields, includeEmptyRows: true };

      const csv = parse(job.summary, opts);
      var link = document.createElement("a");
      let public_signed_url =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      link.setAttribute("href", public_signed_url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "history.csv");
      document.body.appendChild(link);
      link.click();
    },
    dateRangeChange() {
      this.loadHistory();
    },
    searchHistory(search_id) {
      if (search_id == "") {
        this.loadHistory();
      } else {
        MainService.getSearchedCollectionJob({ search_id })
          .then(({ data }) => {
            this.history_data = data.job_list || [];
            this.pagination.total = data.total;
          })
          .catch((err) => {
            this.isError = true;
            return err;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: white;
  margin: 16px 0px;
  padding: 24px;

  .page-title {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #41434c;

    .refresh-btn {
      margin-right: 24px;
      img {
        width: 15px;
        margin-right: 0.5rem;
        text-align: center;
      }
    }
  }

  .history-filter {
    display: flex;
    flex-direction: row;
    .history-search {
      width: 70%;
    }
    .history-date-filter {
      width: 30%;
    }
  }

  .history-list {
    padding: 12px;
    background: #f5f5f5;
  }

  .seo-history-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-history {
      color: #9b9b9b;
      font-size: 12px;
      text-align: center;
    }

    .seo-list-btn-cont {
      display: flex;
      justify-content: flex-end;

      .seo-list-btn {
        display: flex;
        width: 40px;
        height: 40px;
        border: 1px solid #e4e5e6;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .seo-history-box {
    display: flex;
    width: 100%;
    height: auto;
    background-color: white;
    border: 1px solid #e4e5e6;
    padding: 24px;
    border-radius: 4px;
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & + .seo-history-box:nth-child(3n + 1) {
      margin-left: 0;
    }

    .list-container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: 100%;
    }

    .logo {
      width: 72px;
      height: 72px;
      border: 1px solid #e0e0e0;
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .channel-details {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .history-status {
      margin-left: auto;
    }
    .status-success {
      padding: 1px 19px;
      margin-left: auto;
      color: #0a5f23;
      border: 1px solid #0a5f23;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }
    .status-error {
      padding: 1px 19px;
      margin-left: auto;
      color: #cd0909;
      border: 1px solid #cd0909;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }

    .line-1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      display: flex;
    }

    .line-2 {
      color: #9b9b9b;
      line-height: 22px;
      font-size: 12px;
    }

    .cloud {
      cursor: pointer;
    }
  }
}
</style>
