<template>
  <div class="application white-box">
    <div class="section-heading">
      <h3>Sales Channel</h3>
      <p>
        These are the sales channel where this extention is active or inactive
      </p>
    </div>

    <div class="search full-width float-left">
      <nitrozen-input
        type="search"
        placeholder="Search Sales Channels"
        :showSearchIcon="true"
        class="application-list"
        @input="searchApplication()"
        v-model="searchQuery"
      ></nitrozen-input>
    </div>
    <div
      class="card-main columns-4"
      v-if="this.paginated_list && this.paginated_list?.length > 0"
    >
      <div
        class="card-innerbox"
        v-for="(item, i) in this.paginated_list"
        :key="i"
      >
        <div @click="channelClicked(item)" class="card-box">
          <div class="card-logo">
            <img
              v-if="item?.image || item?.logo || item?.logo?.secure_url"
              :src="item?.logo?.secure_url"
            />
          </div>
          <div class="card-content">
            <h4 v-if="item.name">{{ item.name }}</h4>
            <p v-if="item?.domain?.name">{{ item?.domain?.name }}</p>
          </div>
          <div class="button-container">
            <button v-if="item?.config_enabled == true" class="active_button">
              Active
            </button>
            <button v-else class="inactive_button">Inactive</button>
            <div class="select-widget float-right">
              <inline-svg :src="'long-left-arrow'"></inline-svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <nitrozen-pagination
        style="margin-top: 10px"
        v-if="paginated_list.length > 0 && !pageLoading"
        name="Result"
        v-model="pagination"
        @change="setPagination()"
        :pageSizeOptions="[5, 10, 20, 50]"
      ></nitrozen-pagination>
    </div>
    <div v-if="pageLoading">
      <loader></loader>
    </div>
    <div
      v-if="!pageLoading && applications_list?.length == 0"
      class="empty-state"
    >
      <div>
        <img src="../assets/empty_state.png" alt="Page Not Found" />
      </div>
      <div>No Page Found</div>
    </div>
  </div>
</template>

<script>
import { NitrozenInput, NitrozenPagination } from "@gofynd/nitrozen-vue";
import MainService from "./../services/main-service";
import Loader from "../components/loader.vue";
import inlineSvg from "../components/inline-svg.vue";

const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};

export default {
  components: {
    "nitrozen-input": NitrozenInput,
    "nitrozen-pagination": NitrozenPagination,
    Loader,
    "inline-svg": inlineSvg,
  },
  data() {
    return {
      paginated_list: [],
      pagination: { ...PAGINATION },
      applications_list: [],
      all_applications: [],
      pageLoading: false,
      searchQuery: "",
    };
  },
  mounted() {
    this.fetchApplications();
  },
  methods: {
    setPagination(updatedPagination) {
      this.pagination = Object.assign({}, this.pagination, updatedPagination);
      let currIndex = this.pagination.limit * (this.pagination.current - 1);
      this.paginated_list = this.applications_list.slice(
        currIndex,
        currIndex + this.pagination.limit
      );
    },
    fetchApplications() {
      this.pageLoading = true;
      MainService.getAllApplications()
        .then(async ({ data }) => {
          await MainService.getTagsStatus().then((res) => {
            data?.items.map((item) => {
              res?.data?.data.map((ele) => {
                if (ele?.app_id == item?._id)
                  return (item.config_enabled = ele.isTags);
              });
            });
          });

          this.all_applications = data?.items;
          this.applications_list = data?.items;
          this.paginated_list = data?.items.slice(0, this.pagination.limit);
          this.pagination.total = this.applications_list.length;
          this.pageLoading = false;
        })
        .catch(() => {
          this.pageLoading = false;
          this.$snackbar.global.showError(
            "Failed to fetch the list of all applications"
          );
        });
    },
    searchApplication() {
      this.applications_list = this.all_applications.filter((item) => {
        return item.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
      this.setPagination({
        limit: 10,
        current: 1,
        total: this.applications_list.length,
      });
    },
    channelClicked(app) {
      this.$emit("pageChanged", { currentPageIndex: 1, application: app });
    },
  },
  beforeUpdate() {
    if (
      this.searchQuery == null &&
      this.applications_list.length == 0 &&
      this.all_applications.length != 0
    ) {
      this.applications_list = this.all_applications;
    }
    if (this.applications_list.length > 0 && this.paginated_list.length == 0) {
      this.paginated_list = this.applications_list.slice(
        0,
        this.pagination.limit
      );
      this.pagination.total = this.applications_list.length;
    } else if (
      this.applications_list.length == 0 &&
      this.paginated_list.length != 0
    ) {
      this.paginated_list = [];
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  background: white;
  margin: 16px 0px;
  padding: 24px;

  .ext-desc {
    border: 1px solid #e4e5e6;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
    .title {
      font-weight: 700;
      font-size: 20px;
    }
    .desc {
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.application-list {
  padding: 12px;
  background: #f5f5f5;
}

.seo-sales-channels-container {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
  justify-content: space-between;

  .no-application {
    color: #9b9b9b;
    font-size: 12px;
  }
}

.seo-app-box {
  display: flex;
  width: 100%;
  height: auto;
  background-color: white;
  border: 1px solid #e4e5e6;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 24px;

  & + .seo-app-box:nth-child(3n + 1) {
    margin-left: 0;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
  }

  .logo {
    width: 72px;
    height: 72px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .channel-details {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .line-1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }

  .line-2 {
    color: #9b9b9b;
    line-height: 22px;
    font-size: 12px;
  }
}
</style>

<style lang="less" scoped>
.white-box {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 24px;
  background-color: #fff;
}
.section-heading h3 {
  margin-top: 0;
  font-style: normal;
  line-height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: #41434c;
  margin-bottom: 15px;
}
.section-heading p {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: Inter, sans-serif;
  color: #666666;
}
.search {
  margin-bottom: 25px !important;
  float: left;
  width: 100%;
}
.staus {
  display: flex;
  justify-content: space-between;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.filter-box {
  width: 310px;
  display: table-footer-group;
}
.filter-box .nitrozen-dropdown-label {
  position: relative;
  top: 8px;
}
.filter-box .nitrozen-select-wrapper {
  width: 250px;
  float: right;
}
.columns-4 {
  grid-template-columns: 25% 25% 25% 25%;
  width: calc(100% - 54px);
}
.card-main {
  display: grid;
  grid-column-gap: 18px;
}
.card-innerbox {
  margin-bottom: 24px;
}
.card-box {
  display: grid;
  border: 1px solid #e4e5e6;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.card-box:hover {
  box-shadow: 5px 5px 5px 0 lightgrey;
}
.card-logo {
  min-height: 60px;
}
.card-logo img {
  max-width: 52px;
}
.select-widget .inline-svg {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  transform: rotate(180deg);
  padding: 6px 6px;
  display: block;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.card-content {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}
.card-content h4 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #41434c;
}
.card-content p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #828282;
  font-family: Inter, sans-serif;
}

.button-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .inactive_button {
    border: 1px solid #eb5757;
    border-radius: 4px;
    background-color: transparent;
    color: #eb5757;
    width: 74px;
    text-transform: uppercase;
    height: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .active_button {
    border: 1px solid #27ae60;
    border-radius: 4px;
    background-color: transparent;
    color: #27ae60;
    width: 74px;
    text-transform: uppercase;
    height: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
.card-innerbox a {
  display: block;
  width: 100%;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}
.loader-component {
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
