<template>
  <div class="content">
    <div class="page-title">
      <div>
        <div
          :class="['cs-grid-container', `cs-grid-count-${this.items.length}`]"
        >
          <div
            v-for="item in this.items"
            :key="item.key"
            :class="[
              'cs-tab-title',
              'cs-cursor-pointer',
              'cs-center',
              'cs-bp-10',
              { 'cs-active-tab': activeTabKey === item.key },
            ]"
            @click="onChange(item.key)"
          >
            {{ item.label }}
            <div
              v-if="item.count"
              :class="['cs-tab-notify-count', 'cs-center']"
            >
              {{ item.count }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <nitrozen-button
          v-if="history_data.length != 0"
          class="refresh-btn"
          theme="secondary"
          @click="loadHistory"
        >
          <img src="../assets/refresh.svg" />
          Refresh
        </nitrozen-button>
        <nitrozen-button v-flat-btn theme="secondary" @click="onBulkAction"
          >Bulk Action</nitrozen-button
        >
      </div>
    </div>

    <div v-if="openBulkAction">
      <bulk-action-modal
        :selectedChannel="selectedChannel"
        @onClose="closeModal"
      ></bulk-action-modal>
    </div>

    <div v-if="historyDetailsModal">
      <history-status-modal
        :historyDetails="selectedHistory"
        @onClose="closeModal"
      ></history-status-modal>
    </div>

    <div class="history-filter">
      <div class="history-search">
        <nitrozen-input
          type="search"
          placeholder="Search By Job ID"
          :showSearchIcon="true"
          class="history-list"
          @input="searchHistory"
        ></nitrozen-input>
      </div>
      <div class="history-date-filter">
        <date-picker
          class="date-picker filter-input-sm history-list"
          picker_type="date"
          date_format="YYYY-MM-DD"
          v-model="historyDateRange"
          :clearable="true"
          :range="true"
          :not_before="notBefore"
          :not_after="new Date().toISOString()"
          :useNitrozenTheme="true"
          @input="dateRangeChange"
        />
      </div>
    </div>
    <div class="seo-history-container">
      <div
        :key="data"
        class="seo-history-box"
        v-for="data of history_data"
        @click="onHistoryClick(data)"
      >
        <div class="logo">
          <img src="../assets/CSV.svg" />
        </div>
        <div class="channel-details">
          <div class="line-1">
            <span>
              {{ `Job ID : ${data._id}` }}
            </span>
            <img
              v-if="data?.meta?.file_url"
              style="margin-left: 16px"
              src="../assets/cloud.svg"
              class="cloud"
              @click="downloadFile(data)"
            />
          </div>
          <div class="line-2">
            {{ "Uploaded by " }}
            {{
              `${data.uploaded_by?.first_name} ${data.uploaded_by?.last_name}`
            }}
            {{ " | " }} {{ getFormattedDate(data.created_at) }}
          </div>
          <div class="seo-list-btn-cont"></div>
        </div>
        <div class="history-status">
          <div
            :class="{
              'status-scheduled': data.status === 'SCHEDULED',
              'status-inprogress': data.status === 'INPROGRESS',
              'status-success': data.status === 'SUCCESS',
              'status-error': data.status === 'FAILED',
            }"
          >
            {{ data.status }}
          </div>
        </div>
      </div>
      <div v-if="history_data.length == 0 && !pageLoading">
        <div>
          <img src="../assets/empty_state.png" alt="Page Not Found" />
        </div>
        <div class="no-history">No History to Show.</div>
      </div>
    </div>

    <nitrozen-pagination
      v-if="history_data.length != 0 && !pageLoading"
      name="Uploads"
      v-model="pagination"
      @change="setPagination"
      :pageSizeOptions="[5, 10, 20, 50]"
    ></nitrozen-pagination>

    <loader v-if="pageLoading"></loader>
  </div>
</template>

<script>
import {
  NitrozenInput,
  NitrozenButton,
  NitrozenPagination,
} from "@gofynd/nitrozen-vue";
import BulkActionModal from "./bulk-action-modal.vue";
import HistoryStatusModal from "./history-status-modal.vue";
import MainService from "../services/main-service";
import { parse } from "json2csv";
import moment from "moment";
import DatePicker from "../components/date-picker.vue";
import Loader from "../components/loader.vue";

const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};
const MONTHS = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  components: {
    "nitrozen-input": NitrozenInput,
    "nitrozen-button": NitrozenButton,
    BulkActionModal,
    HistoryStatusModal,
    NitrozenPagination,
    DatePicker,
    Loader,
  },
  props: {
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      items: [
        {
          key: "DOWNLOAD",
          label: `Download History`,
          children: `Content of Tab Pane 1`,
        },
        {
          key: "UPLOAD",
          label: `Upload History`,
          children: `Content of Tab Pane 2`,
        },
      ],
      activeTabKey: "DOWNLOAD",
      openBulkAction: false,
      historyDetailsModal: false,
      history_data: [],
      pageLoading: false,
      isError: false,
      companyId: this.$route.params.company_id,
      data: [],
      pagination: { ...PAGINATION },
      scrollIntoView: false,
      selectedHistory: null,
      notBefore: moment("01012020", "DDMMYYYY").toISOString(),
      historyDateRange: ["", ""],
      current_user: "",
    };
  },
  mounted() {
    this.getCurrentUser();
    this.loadHistory();
  },
  methods: {
    onChange(key) {
      this.activeTabKey = key;
      this.loadHistory();
      console.log("clicked");
    },
    onBulkAction() {
      this.openBulkAction = true;
    },
    async getCurrentUser() {
      try {
        let data = await MainService.getCurrentUser();
        this.current_user = data.data.user;
      } catch (error) {
        return error;
      }
    },
    loadHistory() {
      this.history_data = [];
      this.pageLoading = true;
      this.$nextTick(() => {
        if (this.scrollIntoView) {
          this.scrollListIntoVIew();
        }
        this.scrollIntoView = true;
      });
      let params = {
        job_type: this.activeTabKey,
        pagination: {
          page_size: this.pagination.limit || 10,
          page_no: this.pagination.current || 1,
        },
        date_range: this.historyDateRange,
        app: this.selectedChannel,
      };
      MainService.getJobList(params)
        .then(({ data }) => {
          this.history_data = data.job_list || [];
          this.pagination.total = data.total;
        })
        .catch((err) => {
          this.isError = true;
          return err;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    scrollListIntoVIew() {
      const element = this.$refs?.history_data;
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo(0, middle);
    },
    getFormattedDate(date) {
      let d = new Date(date);
      let newDate = `${
        MONTHS[d.getMonth()]
      } ${d.getDate()}, ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${
        d.getHours() >= 12 ? "PM" : "AM"
      }`;
      return newDate;
    },
    onHistoryClick(data) {
      this.historyDetailsModal = true;
      this.selectedHistory = data;
    },
    closeModal() {
      this.openBulkAction = false;
      this.historyDetailsModal = false;
      this.loadHistory();
    },
    setPagination(updatedPagination) {
      this.pagination = Object.assign({}, this.pagination, updatedPagination);
      this.loadHistory();
    },
    // downloadData(data) {
    //   const fields = [
    //     "brand",
    //     "item_code",
    //     "name",
    //     "seo.title",
    //     "seo.description",
    //     "slug",
    //     "status",
    //   ];
    //   const opts = { fields, includeEmptyRows: true };
    //   const csv = parse(data.summary, opts);
    //   var link = document.createElement("a");
    //   let public_signed_url =
    //     "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    //   link.setAttribute("href", public_signed_url);
    //   link.setAttribute("target", "_blank");
    //   link.setAttribute("download", "history.csv");
    //   document.body.appendChild(link);
    //   link.click();
    // },
    downloadFile(data) {
      const downloadUrl = data?.meta?.file_url; // Replace with your file URL
      // Create an anchor element
      const a = document.createElement("a");
      // Set the href attribute to the download URL
      a.href = downloadUrl;
      // Set the download attribute to suggest a default filename (optional)
      a.download = "";

      // Append the anchor to the body (required for some browsers)
      document.body.appendChild(a);

      // Trigger a click event on the anchor
      a.click();

      // Remove the anchor from the document
      document.body.removeChild(a);
    },
    dateRangeChange() {
      this.loadHistory();
    },
    searchHistory(search_id) {
      if (search_id == "") {
        this.loadHistory();
      } else {
        MainService.getSearchedJob({ search_id })
          .then(({ data }) => {
            this.history_data = data.job_list || [];
            this.pagination.total = data.total;
          })
          .catch((err) => {
            this.isError = true;
            return err;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: white;
  margin: 16px 0px;
  padding: 24px;

  .page-title {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #41434c;

    .refresh-btn {
      margin-right: 24px;
      img {
        width: 15px;
        margin-right: 0.5rem;
        text-align: center;
      }
    }
  }

  .history-filter {
    display: flex;
    flex-direction: row;
    .history-search {
      width: 70%;
    }
    .history-date-filter {
      width: 30%;
    }
  }

  .history-list {
    padding: 12px;
    background: #f5f5f5;
  }

  .seo-history-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-history {
      color: #9b9b9b;
      font-size: 12px;
      text-align: center;
    }

    .seo-list-btn-cont {
      display: flex;
      justify-content: flex-end;

      .seo-list-btn {
        display: flex;
        width: 40px;
        height: 40px;
        border: 1px solid #e4e5e6;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .seo-history-box {
    display: flex;
    width: 100%;
    height: auto;
    background-color: white;
    border: 1px solid #e4e5e6;
    padding: 24px;
    border-radius: 4px;
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & + .seo-history-box:nth-child(3n + 1) {
      margin-left: 0;
    }

    .list-container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      width: 100%;
    }

    .logo {
      width: 72px;
      height: 72px;
      border: 1px solid #e0e0e0;
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .channel-details {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .history-status {
      margin-left: auto;
    }

    .status-scheduled {
      padding: 1px 19px;
      margin-left: auto;
      color: blue;
      border: 1px solid blue;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }

    .status-inprogress {
      padding: 1px 19px;
      margin-left: auto;
      color: orange;
      border: 1px solid orange;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }

    .status-success {
      padding: 1px 19px;
      margin-left: auto;
      color: #0a5f23;
      border: 1px solid #0a5f23;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }

    .status-error {
      padding: 1px 19px;
      margin-left: auto;
      color: #cd0909;
      border: 1px solid #cd0909;
      border-radius: 4px;
      height: 21px;
      text-transform: uppercase;
    }

    .line-1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      display: flex;
    }

    .line-2 {
      color: #9b9b9b;
      line-height: 22px;
      font-size: 12px;
    }

    .cloud {
      cursor: pointer;
    }
  }
}
.cs-grid-container {
  display: grid;
}

.cs-grid-count-2 {
  grid-template-columns: repeat(2, 1fr);
}
.cs-grid-count-3 {
  grid-template-columns: repeat(3, 1fr);
}
.cs-grid-count-4 {
  grid-template-columns: repeat(4, 1fr);
}
.cs-grid-count-6 {
  grid-template-columns: repeat(6, 1fr);
}
.cs-grid-count-8 {
  grid-template-columns: repeat(8, 1fr);
}
.cs-grid-count-10 {
  grid-template-columns: repeat(10, 1fr);
}
.cs-grid-count-12 {
  grid-template-columns: repeat(12, 1fr);
}
.cs-grid-count-14 {
  grid-template-columns: repeat(14, 1fr);
}
.cs-grid-count-16 {
  grid-template-columns: repeat(16, 1fr);
}
.cs-grid-count-18 {
  grid-template-columns: repeat(18, 1fr);
}
.cs-grid-count-20 {
  grid-template-columns: repeat(20, 1fr);
}
.cs-grid-count-22 {
  grid-template-columns: repeat(22, 1fr);
}
.cs-grid-count-24 {
  grid-template-columns: repeat(24, 1fr);
}
.cs-grid-count-26 {
  grid-template-columns: repeat(26, 1fr);
}

.cs-tab-title {
  margin-right: 24p;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #4f4f4f;
  border-bottom: 3px solid rgba(46, 48, 190, 0);
}

.cs-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.cs-cursor-pointer {
  cursor: pointer;
}

.cs-active-tab {
  color: #2e31be;
  font-weight: 700;
  border-bottom: 3px solid #2e31be;
}

.cs-bp-10 {
  padding-bottom: 10px;
}

.cs-tab-notify-count {
  background-color: #f2c94c;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  font-size: 12px;
  margin-left: 8px;
  color: #4f4f4f;
}
</style>
