<template>
  <div class="collection-list">
    <div v-if="details.length > 0 || pageLoading" class="content">
      <div class="title">
        <div class="page-title">List of Collections</div>
        <div class="below-title">Select a collection to configure SEO content</div>
      </div>
      <div class="collection-filter">
        <nitrozen-input
          type="search"
          placeholder="Search By Collection name"
          :showSearchIcon="true"
          @input="searchCollectionByName"
          v-model="searchQuery"
          class="collection-search"
        ></nitrozen-input>
      </div>
      <nitrozen-pagination
        style="margin-top:10px"
        v-if="paginated_details.length > 0 && !pageLoading"
        name="Result"
        v-model="pagination"
        @change="setPagination"
        :pageSizeOptions="[5, 10, 20, 50]"
      ></nitrozen-pagination>
      <div class="seo-history-container">
        <div
        @click="clicked(9, data.slug, data.collection_id)"
          :key="data"
          class="seo-history-box"
          v-for="data of paginated_details"
        >
          <div class="channel-details">
            <div class="line-1">
              <span>
                {{ `${data.name}` }}
              </span>
            </div>
            <div class="collection-data">
              <div class="line-2">
                <p class="field">{{ "Created by " }}</p>
                <p class="value">{{ "Not Found" }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Created on " }}</p>
               <p class="value">{{ "Not Found" }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Modified by " }}</p>
                <p class="value">{{ "Not Found" }}</p>
              </div>
              <div class="line-2">
                <p class="field">{{ "Modified on " }}</p>
                <p class="value">{{ "Not Found" }}</p>
              </div>
             </div>
          </div>
          <div class="action-btns">
            <div class="history-status">
              <div v-if="data.isActive == true" class="status-success">
                ACTIVE
              </div>
              <div v-else class="status-error">
                INACTIVE
              </div>
            </div>
          </div>
        </div>
      </div>
      <nitrozen-pagination
        style="margin-top:10px"
        v-if="paginated_details.length > 0 && !pageLoading"
        name="Result"
        v-model="pagination"
        @change="setPagination"
        :pageSizeOptions="[5, 10, 20, 50]"
      ></nitrozen-pagination>
    </div>
    <div v-if="pageLoading">
    <loader></loader>
    </div>
    <div v-if="details?.length == 0 && !pageLoading" class="content">
          <div class="no-history">
            <div class="seo-no-history-box">
                <img src="./../../assets/empty.png" alt="No product to show!">
                <span class="page-title">You haven't created any Collection SEO yet!</span>
                <p class="below-title">Once you create a Collection SEO, it will apppear in the here.</p>
            </div>
          </div>
        </div>
        <div v-if="details.length > 0 && paginated_details?.length == 0 && !pageLoading" class="content">
            <div class="no-history">
              <div class="seo-no-history-box">
                  <img src="./../../assets/empty.png" alt="No product to show!">
                  <span class="page-title">No Product Found!</span>
              </div>
            </div>
          </div>
      </div>  
  </template>

<script>
import {
  NitrozenInput,
  NitrozenPagination,
} from "@gofynd/nitrozen-vue";
import MainService from '../../services/main-service';
import Loader from "../../components/loader.vue";

const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};
const MONTHS = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default {
  data() {
    return {
      details:[],
      collection_data:[],
      paginated_details:[],
      pageLoading: false,
      pagination: { ...PAGINATION },
      currentPage: PAGINATION.current,
      searchQuery:null,
      searched_collection:[],
    };
  },
  components:{
    NitrozenInput,
    NitrozenPagination,
    Loader,
  },
  mounted(){
    this.getAllCollectionsData();
  },
  props:{
    selectedChannel: {
      type: Object,
    },
    scriptStatus: {
      type: Boolean,
    },
  },
  methods: {
    clicked(a, slug, collection_id){
        this.$emit("pageChanged", { currentPageIndex: a, slug: slug, uid: collection_id, scriptStatus: this.scriptStatus });
      },
    setPagination(updatedPagination) {
        this.pagination = Object.assign({}, this.pagination, updatedPagination);
        let currIndex = this.pagination.limit * (this.pagination.current - 1);
        this.currentPage = updatedPagination.current;
        this.paginated_details = this.collection_data.slice(
          currIndex,
          currIndex + this.pagination.limit
        );
      },
    getFormattedDate(date) {
      let d = new Date(date);
      let newDate = `${
        MONTHS[d.getMonth()]
      } ${d.getDate()}, ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${
        d.getHours() >= 12 ? "PM" : "AM"
      }`;
      return newDate;
    },
    async getAllCollectionsData() {
      this.pageLoading = true;
      return await MainService.getAllCollections({
        app: this.selectedChannel
      })
        .then(({ data }) => {
          this.details = [];
          data.data.map((product) => {     
            this.details.push({
              collection_id: product.uid,
              name: product.name,
              slug: product.slug,
              isActive: product.is_active,
              created_on: product._schedule?.start  
            });
          });
        })
        .catch((err) => {
          this.$snackbar.global.showError(`${err} while fetching collections`);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    searchCollectionByName(searchQuery) {
        this.collection_data = this.details.filter(ele =>
          ele?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        
        this.setPagination({
          limit: 10,
          current: 1,
          total: this.collection_data.length
        });
      },
  },
  beforeUpdate() {
    if (
        this.searchQuery == null &&
        this.collection_data.length == 0 &&
        this.details.length != 0
      ) {
        this.collection_data = this.details;
      }
      if (
        this.collection_data.length > 0 &&
        this.paginated_details.length == 0
      ) {
        this.paginated_details = this.collection_data.slice(
          0,
          this.pagination.limit
        );
        this.pagination.total = this.collection_data.length;
      } else if (
        this.collection_data.length == 0 &&
        this.paginated_details.length != 0
      ) {
        this.paginated_details = [];
      }
    }
};
</script>

<style lang="less" scoped>
.collection-list {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
}
.title {
  display: flex;
  float: left;
  flex-direction: column;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.below-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #666666;
}
.content {
  display: flex;
  flex-direction: column;
  background: white;
  margin: 16px 0px;
  padding: 24px;
  border-radius: 10px;

  .page-title {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #41434c;
  }

  .collection-filter {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 16px;
    width: auto;
    height: auto;

    background: #f8f8f8;
    border-radius: 4px;
  }
  .collection-search {
    box-sizing: border-box;

    width: 100%;
    height: 40px;

    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .seo-history-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-history {
      color: #9b9b9b;
      font-size: 12px;
      text-align: center;
    }
  }

  .seo-history-box {
    display: flex;
  width: 100%;
  height: auto;
  background-color: white;
  border: 1px solid #e4e5e6;
  padding: 17px;
  border-radius: 4px;
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
    & + .seo-history-box:nth-child(3n + 1) {
      margin-left: 0;
    }
  
  .seo-history-box:hover{
    box-shadow: 2px 2px 2px 0 lightgrey;
  }

    .channel-details {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .collection-data{
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    .action-btns {
      display: flex;
      flex-direction: column;
      gap: 17px;
      margin-left: auto;
    }

    .history-status {
      margin-left: auto;
    }

    .status-success {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #0a5f23;
      padding: 1px 10px;
      margin-left: auto;
      border: 1px solid #0a5f23;
      border-radius: 2px;
      height: 21px;
      text-transform: uppercase;
    }
    .status-error {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #9b9b9b;

      padding: 1px 10px;
      margin-left: auto;
      border: 1px solid #9b9b9b;
      border-radius: 2px;
      height: 21px;
      text-transform: uppercase;
    }

    .line-1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      display: flex;
    }

    .line-2 {
      display: flex;
      align-items: baseline;
      gap: 8px;
    }

    .field{
      color: #9B9B9B;
      font-size: 10px;
    }
    
    .value{
      color: #41434C;
      font-size: 12px;
    }
  }

  .seo-no-history-box{
      display: flex;
    width: 100%;
    height: auto;
    background-color: white;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
    }
}
.sun-editor-editable h1{
-webkit-margin-before: unset !important;
-webkit-margin-after: unset !important;
}
</style>
